(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("AMap"));
	else if(typeof define === 'function' && define.amd)
		define(["AMap"], factory);
	else if(typeof exports === 'object')
		exports["its-vue-app"] = factory(require("AMap"));
	else
		root["its-vue-app"] = factory(root["AMap"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_AMap__) {
return 