/**
 * 缓存车辆信息
 * author:young
 */

const addVehicle = {
  state: {
    yhsdp: null,
    interactiveArr: [], // 下发指令返回
    statusList: [],
    terminalType: {
      0: "808",
      2: "808",
      1: "905"
    },
    globalAlarmArr: [], // websocket推送的报警数据
    globalPlatFormArr: [], // websocket推送的查岗和督办数据
    globalOfflineArr: [], // websocket推送的长时间离线车辆
    websocketOnError: 0, // websocket 错误重连次数
    screenCoefficient: 1, // table宽度系数，根据屏幕分辨率适应
    terminalObj: null, //去查询终端属性的终端号obj
    terminalPropertiesList: [], //查询终端属性的结果
    terminalFilters: [], //查询终端属性的终端型号
    terminalHardFilters: [], //查询终端属性的硬件版本号
    terminalSoftFilters: [], //查询终端属性的固件版本号
    downLoadList: [], //历史视频下载列表
    downLoadLength: 0, //未下载列表数值（等待生成中，2.生成中）
    refreshDownLoad: false, //下载刷新状态,
    refreshDailog: false, //车辆面板刷新状态,
    flicker: false, //报警屏幕闪烁
    // 20230425 谢 过期驾驶员、车辆
    expireDriversArr: [],
    expireCarsArr: []
  },

  mutations: {
    add_expireDriverData(state, param) {
      state.expireDriversArr = param;
    },
    add_expireCarData(state, param) {
      state.expireCarsArr = param;
    },
    setRefreshDailogStatus(state, param) {
      state.refreshDailog = param;
    },
    setRefreshStatus(state, param) {
      state.refreshDownLoad = param;
    },
    add_yhsdp(state, param) {
      if (param) {
        state.yhsdp = param;
      }
    },
    add_interactiveArr(state, param) {
      if (param) {
        /** 最多显示200条 */
        state.interactiveArr.unshift(param);
        if (state.interactiveArr > 200) {
          state.interactiveArr.splice(state.interactiveArr.length - 1, 1);
        }
      }
    },
    change_interactiveArr(state, param) {
      if (param) {
        /** 确认指令信息位置 */
        const { orderId, terminalNo, sTime, sNote, commandFlag } = param;
        const index = state.interactiveArr.findIndex(item => {
          return (
            item.status === 0 &&
            `${terminalNo}${orderId}` === `${item.terminalNo}${item.orderId}`
          );
        });
        if (index !== -1) {
          state.interactiveArr[index].receiveTime = sTime;
          state.interactiveArr[index].sNote = sNote;
          state.interactiveArr[index].status = 1;
          /**实时拍照数据处理 */
          if (param.imgId) state.interactiveArr[index].imgId = param.imgId;
        }
        if (commandFlag) {
          const commandIndex = state.interactiveArr.findIndex(item => {
            return (
              `${terminalNo}${orderId}` === `${item.terminalNo}${item.orderId}`
            );
          });
          console.log(commandIndex);
          state.interactiveArr[commandIndex].sNote = sNote;
        }
      }
    },
    add_globalAlarm(state, param) {
      if (param) {
        state.globalAlarmArr.unshift(param);
      }
    },
    //保存附件督办和查岗数据
    add_globalPlatform(state, param) {
      param && state.globalPlatFormArr.unshift(param);
    },
    add_globalOffline(state, param) {
      if (param) {
        state.globalOfflineArr.unshift(param);
      }
    },
    add_statusList(state, param) {
      state.statusList = param;
    },
    clear_interactiveArr(state, param) {
      state.interactiveArr = [];
    },
    clear_all(state, param) {
      state.yhsdp = null;
      state.interactiveArr = [];

      state.downLoadList = [];
      state.downLoadLength = 0;
      state.refreshDownLoad = false;
      state.refreshDailog = false;

      state.globalAlarmArr = [];
      state.globalPlatFormArr = [];
      state.globalOfflineArr = [];

      state.statusList = [];
      state.websocketOnError = 0;

      state.screenCoefficient = 0;
      state.terminalObj = null;
      state.terminalPropertiesList = [];
      state.terminalFilters = [];
      state.terminalHardFilters = [];
      state.terminalSoftFilters = [];
      state.expireDriversArr = [];
      state.expireCarsArr = [];
    },
    cutGlobalAlarm(state, param) {
      state.globalAlarmArr.splice(0, 1);
    },
    // 移除指定项查岗或者督办消息
    cutIndexGlobalPlatForm(state, param) {
      state.globalPlatFormArr.splice(param, 1);
    },
    //删除车辆报警数据的最后一条
    popGlobalAlarm(state, param) {
      state.globalAlarmArr.pop();
    },
    /**移除指定项的车辆报警数据 */
    cutIndexGlobalAlarm(state, param) {
      state.globalAlarmArr.splice(param, 1);
    },
    /**移除指定项的长时间离线车辆消息 */
    cutIndexGlobalLongOffline(state, param) {
      state.globalOfflineArr.splice(param, 1);
    },
    //删除长时间离线车辆数据的最后一条
    popGlobalLongOffline(state, param) {
      state.globalOfflineArr.pop();
    },
    clearGlobalAlarm(state, param) {
      state.globalAlarmArr = [];
    },

    set_screenCoefficient(state, param) {
      state.screenCoefficient = param;
    },
    addWebsocketOnError(state, param) {
      state.websocketOnError = param;
    },
    clearWebsocketOnError(state) {
      state.websocketOnError = 0;
    },
    set_terminalObj(state, param) {
      state.terminalObj = param
        ? {
            ...param
          }
        : param;
    },
    add_terminalPropertiesList(state, param) {
      state.terminalPropertiesList.push(param);
      if (state.terminalFilters.indexOf(param.terminalModel) === -1) {
        state.terminalFilters.push(param.terminalModel);
      }
      if (state.terminalHardFilters.indexOf(param.hardwareVersion) === -1) {
        state.terminalHardFilters.push(param.hardwareVersion);
      }
      if (state.terminalSoftFilters.indexOf(param.softwareVersion) === -1) {
        state.terminalSoftFilters.push(param.softwareVersion);
      }
    },
    clear_terminalPropertiesList(state, param) {
      state.terminalPropertiesList = [];
      state.terminalFilters = [];
      state.terminalHardFilters = [];
      state.terminalSoftFilters = [];
    },
    set_downLoadList(state, param) {
      state.downLoadList = param;
    },
    set_downLoadListLength(state, param) {
      state.downLoadLength = param;
    },
    flickerStatus(state, param) {
      state.flicker = param;
    }
  },
  actions: {
    add_expireDriverData({ commit }, param) {
      commit("add_expireDriverData", param);
    },
    add_expireCarData({ commit }, param) {
      commit("add_expireCarData", param);
    },
    setRefreshDailogStatus({ commit }, param) {
      commit("setRefreshDailogStatus", param);
    },
    setRefreshStatus({ commit }, param) {
      commit("setRefreshStatus", param);
    },

    add_yhsdp({ commit }, param) {
      commit("add_yhsdp", param);
    },
    add_interactiveArr({ commit }, param) {
      commit("add_interactiveArr", param);
    },
    change_interactiveArr({ commit }, param) {
      commit("change_interactiveArr", param);
    },

    clear_interactiveArr({ commit }, param) {
      commit("clear_interactiveArr", param);
    },

    clear_all({ commit }, param) {
      commit("clear_all", param);
    },
    add_globalAlarm({ commit }, param) {
      commit("add_globalAlarm", param);
    },
    add_globalPlatform({ commit }, param) {
      commit("add_globalPlatform", param);
    },
    add_globalOffline({ commit }, param) {
      commit("add_globalOffline", param);
    },
    cutGlobalAlarm({ commit }, param) {
      commit("cutGlobalAlarm", param);
    },
    cutIndexGlobalPlatForm({ commit }, param) {
      commit("cutIndexGlobalPlatForm", param);
    },
    popGlobalAlarm({ commit }, param) {
      commit("popGlobalAlarm", param);
    },
    popGlobalLongOffline({ commit }, param) {
      commit("popGlobalLongOffline", param);
    },
    cutIndexGlobalAlarm({ commit }, param) {
      commit("cutIndexGlobalAlarm", param);
    },
    cutIndexGlobalLongOffline({ commit }, param) {
      commit("cutIndexGlobalLongOffline", param);
    },
    clearGlobalAlarm({ commit }, param) {
      commit("clearGlobalAlarm", param);
    },
    add_statusList({ commit }, param) {
      commit("add_statusList", param);
    },
    set_screenCoefficient({ commit }, param) {
      commit("set_screenCoefficient", param);
    },
    addWebsocketOnError({ commit }, param) {
      commit("addWebsocketOnError", param);
    },
    clearWebsocketOnError({ commit }, param) {
      commit("clearWebsocketOnError", param);
    },
    set_terminalObj({ commit }, param) {
      commit("set_terminalObj", param);
    },
    add_terminalPropertiesList({ commit }, param) {
      commit("add_terminalPropertiesList", param);
    },
    clear_terminalPropertiesList({ commit }, param) {
      commit("clear_terminalPropertiesList", param);
    },
    set_downLoadList({ commit }, param) {
      commit("set_downLoadList", param);
    },
    set_downLoadListLength({ commit }, param) {
      commit("set_downLoadListLength", param);
    },
    flickerStatus({ commit }, param) {
      commit("flickerStatus", param);
    }
  }
};
export default addVehicle;
