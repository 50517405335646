<template>
  <div id="app" :class="flicker?'app':''">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      theme: 'theme-project-gps',
      flicker:false,
    }
  },
  methods: {},
  created() {
    if (window.localStorage.getItem('YH_BF_THEME')) {
      this.theme = window.localStorage.getItem('YH_BF_THEME')
    }
    document.body.classList.add(this.theme)
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem('store'))
        )
      )
      sessionStorage.removeItem('store')
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  mounted() {
    window.addEventListener('setItem', (e) => {
      this.theme = e.newValue
    })
  },
  destroyed() {
    // window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    // window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  watch: {
    theme(val, oldVal) {
      document.body.classList.remove(oldVal)
      document.body.classList.add(val)
    },
    '$store.state.vehicle.flicker'(val, oldVal) {
      this.flicker = val;
    },
  }
}
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
  display: flex;
  position: relative;
}
.app::before{
  content:'';
  position: fixed;
  z-index: 4444;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;  
  animation: anim .5s 3 linear;
  background: rgba(238, 21, 21, 0.2);
}
@keyframes anim{
  // 0%{
  //   background-image: radial-gradient(transparent 85%, rgba(246, 50, 50, 0.7) 100%);  
  // }100%{
  //   background-image: radial-gradient(transparent 100%);  
  // }
  0% {
    opacity: 0;
    transform: translate(4px,5px);
  }
  100% {
    opacity: 1;
    transform: translate(0,-3px);
  }
}
</style>
