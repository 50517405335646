import axios from 'axios'
import baseConfig from '../static/config'
import {
  Message
} from 'element-ui'
/** **** 天地图逆地址解析 ******/

const service = axios.create({
  baseURL:"http://api.tianditu.gov.cn/geocoder",  
  timeout: 60000 // 请求超时时间
})
/** **** request拦截器==>对请求参数做处理 ******/
service.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type': 'application/json;charset=UTF-8',
    }
    return config
  },
  error => {
    // 请求错误处理
    console.log(error, 'error')
    Message.error({
      message: '服务器开小差啦~'
    })
  }
)
/** **** respone拦截器==>对响应做处理 ******/
service.interceptors.response.use(
  response => {   
    return Promise.resolve(response.data)
  },
  error => {
    console.log('error',error)    
    return Promise.reject(error)
  }
)
const getTianDituPlace = data => {  
  return service({
    method: 'get',
    params: Object.assign(data,{
      type: 'geocode',
      tk:"ca096469b02fb060b3319cc68eec00bd",//浏览器端
    })
  })
}


export default getTianDituPlace
