/*
create by gw : 2018-09-13  用于存放、实现工具函数

/**
 * 实时信息枚举
 */

export const RefreshTypeEnum = Object.freeze({
  GPS_Vehicle: 0x100, // gps车辆上下线提醒 256
  // GPS_OnInspection: 0x1301, // gps查岗
  GPS_Image: 0x101, // gps图像实时数据 257
  GPS_Alarm: 0x102, // gps报警实时数据
  GPS_InteractionDataOut: 0x103, // gps交互信息
  GPS_SendSingeName: 0x105, // gps发送登签语音播报
  GPS_AlarmSupervisionInformation: 0x106, // gps平台交流,报警督办信息
  // GPS_PlatformCommunication: 0x107, // gps平台交流,暂时只做上下级交流
  GPS_PlatformCommunication: 0x1301, // gps查岗
  GPS_ReturnOfCommand: 0x108, // gps下发指令,需要返回数据的
  GPS_Logout: 0x109, // gps下发指令,需要返回数据的
  GPS_QueryTerminalResList: 0x200, // 查看历史视频,需要获取返回资源列表
  GPS_RealVideo: 0x201, // 查看实时视频,成功或失败
  GPS_HistoryVideo: 0x202, // 查看历史视频,成功或失败
  GPS_DownLoadHistoryVideo: 0x203, // 历史视频上传,
  GB_QueryAlarmSeting: 0x204, // 查询终端参数报警屏蔽设置,
  GB_QueryAlarmSetingLimit: 0x301, // 查询终端参数载重限高,
  GB_QueryAlarmSetingAV: 0x302, //音视频
  GB_QueryAttributes: 0x205, //查询终端属性,517
  GB_QueryTerminalProperties: 0x206, //查询终端属性,
  GB_TerminalInterval: 0x207, //开始执行等待查询终端属性的结果,
  GB_Remind: 0x208, //提醒用户系统已更新
  GB_Cash: 0x104, //用户抢占视频资源---谢自定义的
  GPS_LongOffline: 0x300 //长时间离线通知
});

/**
 * 操作指令相对应的英文字符
 */
export const opertionType = new Map([
  [0x8201, "点名"],
  [0x8300, "文本信息下发-消息类型-消息内容"],
  [0x8104, "查询终端参数"],
  [0x8103, "设置终端参数"],
  [0x8107, "查询终端属性"],
  [0x8899, "终端升级"],
  [0xfeeb, "设置IC卡参数"],
  [0x0055, "设置终端参数-设置最高速度(km/h)"],
  [0x005b, "设置终端参数-设置预超速提前量(km/h)"],
  [0x0056, "设置终端参数-设置超速持续时间(s)"],
  [0x0057, "设置终端参数-设置连续驾驶时间(分钟)"],
  [0x005c, "设置终端参数-设置预疲劳提前量(分钟)"],
  [0x0058, "设置终端参数-设置当天累计驾驶时间(分钟)"],
  [0x0059, "设置终端参数-设置最小休息时间(分钟)"],
  [0x0001, "设置终端参数-设置心跳间隔(s)"],
  [0x0010, "设置终端参数-设置主服务器APN "],
  [0x0013, "设置终端参数-设置主服务器IP或域名 "],
  [0x0018, "设置终端参数-设置主服务器端口 "],
  [0x0017, "设置终端参数-设置备份服务器IP或域名 "],
  [0x0019, "设置终端参数-设置备份服务器端口 "],
  [0x9101, "实时音视频传输请求"],
  [0x9205, "查询终端资源列表(历史视频)"],
  [0x9201, "平台下发远程录像回放请求(历史视频)"],
  [0x9202, "历史视频控制请求"],
  [0x9206, "历史视频上传请求"],

  [0x0020, "设置终端参数-设置位置汇报策略"],
  [0x0021, "设置终端参数-设置位置汇报方案 "],
  [0x0022, "设置终端参数-设置未登录汇报时间间隔（s） "],
  [0x0023, "设置终端参数-设置ACC OFF汇报时间间隔（s）"],
  [0x0024, "设置终端参数-设置ACC ON汇报时间间隔（s） "],
  [0x0025, "设置终端参数-设置空车汇报时间间隔（s）"],
  [0x0026, "设置终端参数-设置重车汇报时间间隔（s） "],
  [0x0027, "设置终端参数-设置休眠时汇报时间间隔（s） "],
  [0x0028, "设置终端参数-设置紧急报警时汇报时间间隔（s）"],
  [0x0029, "设置终端参数-设置未登录汇报距离间隔（m） "],
  [0x002a, "设置终端参数-设置ACC OFF汇报距离间隔（m）"],
  [0x002b, "设置终端参数-设置ACC ON汇报距离间隔（m） "],
  [0x002c, "设置终端参数-设置空车汇报距离间隔（m） "],
  // [0x002D, '设置终端参数-设置重车汇报距离间隔（m） '],//这是出租车的
  [0x002d, "设置终端参数-司机未登签距离间隔（m） "], //这是gps的
  [0x002e, "设置终端参数-设置休眠时汇报距离间隔（m） "],
  [0x002f, "设置终端参数-设置紧急报警时汇报距离间隔（m） "],
  [0x0030, "设置终端参数-设置拐点补传角度 ", "度"],
  [0x005a, "设置终端参数-设置当天累计驾驶时间门限(分钟)"],
  [0x0011, "设置终端参数-主服务器无线通信拨导用户名设置"],
  [0x0012, "设置终端参数-主服务器无线通信拨号密码设置"],
  [0x0014, "设置终端参数-备份服务器APN设置 "],
  [0x0015, "设置终端参数-备份服务器无线通信拨导用户名设置"],
  [0x0016, "设置终端参数-备份服务器无线通信拨号密码设置"],
  [0x8b10, "下行数据透传"],

  [0xf372, "设置终端参数-载重监测参数设置"],
  [0xf373, "设置终端参数-限高监测参数设置"],
  [0x0075, "设置终端参数-音视频参数设置"],
  [0xf001, "设置终端参数-限速参数设置"],
  [0xf364, "设置终端参数-驾驶员辅助参数设置"],
  [0xf365, "设置终端参数-驾驶员行为监测参数设置"],
  [0xf366, "设置终端参数-轮胎状态监测参数设置"],
  [0xf367, "设置终端参数-盲区监测参数设置"],
  [0xf370, "设置终端参数-激烈驾驶参数设置"],
  [0x0095, "设置终端参数-计价器设置"],
  [0x8700, "查询车辆行驶记录"]
]);

/**
 * 查询终端参数 =》 所有终端参数
 */
export const parametersKey2 = [
  /** 1主份服务器配置 */
  { id: 0x0010, name: "主服务器APN：" }, // 16进制
  { id: 0x0011, name: "主服务器无线通信拨导用户名：" }, // 16进制
  { id: 0x0012, name: "主服务器无线通信拨号密码：" }, //
  { id: 0x0013, name: "主服务器IP地址：" }, //
  { id: 0x0018, name: "主服务器TCP端口号：" }, //
  /** 2备份服务器配置 */
  { id: 0x0014, name: "备份服务器APN：" }, //
  { id: 0x0015, name: "备份服务器无线通信拨导用户名：" }, //
  { id: 0x0016, name: "备份服务器无线通信拨号密码：" }, //
  { id: 0x0017, name: "备份服务器IP地址：" }, //
  { id: 0x0019, name: "备份服务器TCP端口号：" }, //

  /** 3运营汇报策略配置 */
  { id: 0x0020, name: "位置汇报策略：" }, //
  { id: 0x0021, name: "位置汇报方案：" }, //
  { id: 0x0022, name: "未登录汇报时间间隔：" }, // 16进制
  { id: 0x0024, name: "ACC ON汇报时间间隔（s）：" }, //
  { id: 0x0023, name: "ACC OFF汇报时间间隔（s）：" }, //
  { id: 0x0025, name: "空车汇报时间间隔（s）：" }, //
  { id: 0x0026, name: "重车汇报时间间隔（s）：" }, //
  { id: 0x0027, name: "休眠汇报时间间隔（s）：" }, // 16进制
  { id: 0x0028, name: "紧急报警汇报时间间隔（s）：" }, //
  { id: 0x0029, name: "未登录汇报距离（m）：" }, // 16进制
  { id: 0x002b, name: "ACC ON汇报距离（m）：" }, //
  { id: 0x002a, name: "ACC OFF汇报距离（m）：" }, //
  { id: 0x002c, name: "空车汇报距离（m）：" }, //
  { id: 0x002d, name: "重车汇报距离（m）：" }, // 16进制
  { id: 0x002e, name: "休眠汇报距离（m）：" }, // 16进制
  { id: 0x002f, name: "紧急报警汇报距离（m）：" }, // 16进制
  { id: 0x0030, name: "拐点补传角度：" }, //
  /** 4行车配置 */
  { id: 0x0055, name: "最高速度门限（km/h）：" }, //
  { id: 0x0056, name: "超速持续时间（s）：" }, //
  { id: 0x0057, name: "连续驾驶时限（分钟）：" }, // 16进制

  { id: 0x005a, name: "当天累计驾驶时限（分钟）：" }, //
  { id: 0x0058, name: "最小休息时间（分钟）：" }, //
  { id: 0x0059, name: "最长停车时间（分钟）：" }, //
  { id: 0x0001, name: "心跳发送间隔：" }, //

  //音视频设置
  { id: 0x0075, name: "设置音视频参数：" }
];

/**
 * 设置终端参数 =》 所有终端参数
 */
export const alarmShield = [
  "紧急报警、触动报警开关后触发",
  "预警",
  "卫星定位模块发生故障",
  "卫星定位天线未接或被剪断",
  "卫星定位天线短路",
  "ISU主电源欠压",
  "UISU主电源掉电",
  "液晶(LCD)显示ISU故障",
  "语音合成(TTS)模块故障",
  "摄像头故障",
  "计价器故障",
  "服务评价器故障(前后排)",
  "LED广告屏故障",
  "液晶(LCD)显示屏故障",
  "安全访问模块故障",
  "LED顶灯故障",
  "超速报警",
  "连续驾驶超时",
  "当天累计驾驶超时",
  "超时停车",
  "进出区域/路线",
  "路段行驶时间不足/过长",
  "禁行路段行驶",
  "车速传感器故障",
  "车辆非法点火",
  "车辆非法位移",
  "ISU存储异常",
  "录音设备故障",
  "计价器时钟超过规定误差"
]; //出租车屏蔽报警
//GPS屏蔽报警
export const alarmShieldGps = [
  "紧急报警、触动报警开关后触发",
  "超速报警",
  "疲劳驾驶报警",
  "危险驾驶行为报警",
  "GNSS模块发生故障报警",
  "GNSS天线未接或被剪断报警",
  "GNSS天线短路报警",
  "终端主电源欠压报警",
  "终端主电源掉电报警",
  "终端LCD或显示器故障报警",
  "TTS模块故障报警",
  "摄像头故障报警",
  "道路运输证IC卡模块故障报警",
  "超速预警",
  "疲劳驾驶预警",
  "违规行驶报警",
  "胎压预警",
  "右转盲区异常报警",
  "当天累计驾驶超时报警",
  "超时停车报警",
  "进出区域报警",
  "进出路线报警",
  "路段行驶时间不足/过长报警",
  "路线偏离报警",
  "车辆VSS故障",
  "车辆油量异常报警",
  "车辆被盗报警（通过车辆防盗器）",
  "车辆非法点火报警",
  "车辆非法移位报警",
  "碰撞侧翻报警",
  "侧翻预警"
];

export class YHTools {
  /**
   * 日期格式化输出
   * @param {date} dtTime
   * @param {string} sFormat
   */
  static DateFormat(dtTime, sFormat) {
    try {
      const obj = {
        "M+": dtTime.getMonth() + 1, // 月份
        "d+": dtTime.getDate(), // 日
        "H+": dtTime.getHours(), // 小时
        "m+": dtTime.getMinutes(), // 分
        "s+": dtTime.getSeconds(), // 秒
        "q+": Math.floor((dtTime.getMonth() + 3) / 3), // 季度
        S: dtTime.getMilliseconds() // 毫秒
      };
      if (/(y+)/.test(sFormat)) {
        sFormat = sFormat.replace(
          RegExp.$1,
          `${dtTime.getFullYear()}`.substr(4 - RegExp.$1.length)
        );
      }
      Object.keys(obj).forEach(key => {
        if (new RegExp(`(${key})`).test(sFormat)) {
          sFormat = sFormat.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? obj[key]
              : `00${obj[key]}`.substr(`${obj[key]}`.length)
          );
        }
      });
      return sFormat;
    } catch (error) {
      console.error("Tools  DateFormat  error: " + error.message);
      return "";
    }
  }

  /**
   * 根据秒获取小时 分钟 秒
   * 最小为1分钟(60),最大值4个小时15分钟(15300)
   * @param {number} uiSeconds
   * @param {boolean} flag   输出00：00：00 或  xx天xx小时xx分钟xx秒
   */
  static FormatTimeBySeconds(uiSeconds, flag) {
    try {
      var s = 0; // 秒
      var m = 0; // 分
      var h = 0; // 小时
      var d = 0; // 天
      if (uiSeconds >= 60) {
        m = parseInt(uiSeconds / 60);
        s = parseInt(uiSeconds % 60);
        if (m >= 60) {
          h = parseInt(m / 60);
          m = parseInt(m % 60);
          if (h >= 24 && flag) {
            d = parseInt(h / 24);
            h = parseInt(h % 24);
          }
        }
      } else {
        s = uiSeconds;
      }
      const seconds = s ? `${s} 秒 ` : ""; // 秒
      const minutes = m ? `${m} 分钟 ` : ""; // 分
      const hours = h ? `${h} 小时 ` : ""; // 小时
      const days = d ? `${d} 天 ` : ""; // 天
      const str = `${days}${hours}${minutes}${seconds}`;

      const ho = h ? `${h.toString().padStart(2, "0")}:` : "00:"; // 小时

      return flag
        ? str
        : `${ho}${m.toString().padStart(2, "0")}:${s
            .toString()
            .padStart(2, "0")}`;
    } catch (error) {
      console.error("Tools  FormatTimeBySeconds  error: " + error.message);
    }
  }
}
