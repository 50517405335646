import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
// import Layout from '@/views/layout/Home'
import EmptyLayout from "@/views/layout/EmptyLayout";
Vue.use(Router);

/**
 * 当点击本页面链接的导航，当前位置的冗余导航报错
 */
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: "/",
    redirect: "/platform"
  },
  {
    path: "/login",
    component: resolve => require(["@/views/login/login.vue"], resolve),
    meta: {
      title: "登录"
    }
  },

  {
    path: "/404",
    component: resolve => require(["@/components/common/404.vue"], resolve),
    meta: {
      title: "404"
    }
  },
  {
    path: "/500",
    component: resolve => require(["@/components/common/500.vue"], resolve),
    meta: {
      title: "500"
    }
  },
  {
    path: "/layout",
    component: EmptyLayout,
    redirect: "noRedirect",
    children: [
      {
        path: "/platform",
        component: () => import("@/views/login/platform.vue"),
        meta: {
          title: "平台"
        }
      },
      /** 出租路由 */
      {
        path: "",
        component: resolve => require(["@/views/layout/Home.vue"], resolve),
        meta: {
          title: ""
        },
        children: [
          {
            path: "/projectTaxi",
            redirect: "/projectTaxi/dashboard"
          },
          {
            path: "/projectTaxi/dashboard",
            component: resolve =>
              require(["@/views/projectTaxi/dashboard/dashboard.vue"], resolve),
            meta: {
              title: "数据看板"
            }
          },
          {
            path: "/projectTaxi/monitoring",
            component: resolve =>
              require(["@/views/monitor/monitoring/monitoring.vue"], resolve),
            meta: {
              title: "综合监控"
            }
          },
          {
            path: "/projectTaxi/monitoringNew",
            component: resolve =>
              require([
                "@/views/monitor/monitoring/monitoringNew.vue"
              ], resolve),
            meta: {
              title: "综合监控（自建）"
            }
          },
          {
            path: "/projectTaxi/timedVoiceDistribution",
            component: resolve =>
              require([
                "@/views/projectGps/timedVoiceDistribution/timedVoiceDistribution.vue"
              ], resolve),
            meta: {
              keepAlive: true,
              title: "定时下发语音"
            }
          },
          {
            path: "/projectTaxi/alarmManagement",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "报警管理"
            },
            children: [
              {
                path: "/projectTaxi/alarmDeal",
                component: resolve =>
                  require(["@/views/monitor/alarmDeal/alarmDeal.vue"], resolve),
                meta: {
                  title: "报警处理"
                }
              },
              {
                path: "/projectTaxi/alarmSetting",
                component: resolve =>
                  require([
                    "@/views/monitor/alarmSetting/alarmSetting.vue"
                  ], resolve),
                meta: {
                  title: "报警配置"
                }
              },
              {
                path: "/projectTaxi/faceRecognitionResults",
                component: resolve =>
                  require([
                    "@/views/projectTaxi/faceRecognitionResults/faceRecognitionResults.vue"
                  ], resolve),
                meta: {
                  title: "人脸识别结果"
                }
              },
            ]
          },
          {
            path: "/projectTaxi/customerService",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "客户服务"
            },
            children: [
              {
                path: "/projectTaxi/complaintManagement",
                component: resolve =>
                  require([
                    "@/views/projectTaxi/customerService/complaintManagement/complaintManagement.vue"
                  ], resolve),
                meta: {
                  title: "投诉管理"
                }
              },
              {
                path: "/projectTaxi/serviceEvaluation",
                component: resolve =>
                  require([
                    "@/views/projectTaxi/customerService/serviceEvaluation/serviceEvaluation.vue"
                  ], resolve),
                meta: {
                  title: "服务评价"
                }
              },
              {
                path: "/projectTaxi/lostAndFound",
                component: resolve =>
                  require([
                    "@/views/projectTaxi/customerService/lostAndFound/lostAndFound.vue"
                  ], resolve),
                meta: {
                  title: "失物查找"
                }
              }
            ]
          },
          {
            path: "/projectTaxi/businessReport",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "业务报表"
            },
            children: [
              {
                path: "/projectTaxi/operationDataStatistics",
                component: resolve =>
                  require([
                    "@/views/projectTaxi/businessReport/operationDataStatistics/operationDataStatistics.vue"
                  ], resolve),
                meta: {
                  title: "运营数据统计"
                }
              },
              {
                path: "/projectTaxi/vehicleDataStatistics",
                component: resolve =>
                  require([
                    "@/views/projectTaxi/businessReport/serviceDataStatistics/vehicleDataStatistics.vue"
                  ], resolve),
                meta: {
                  title: "车辆服务统计"
                }
              },
              {
                path: "/projectTaxi/driverDataStatistics",
                component: resolve =>
                  require([
                    "@/views/projectTaxi/businessReport/serviceDataStatistics/driverDataStatistics.vue"
                  ], resolve),
                meta: {
                  title: "驾驶员服务统计"
                }
              },
              {
                path: "/projectTaxi/vehicleOnlineRate",
                component: resolve =>
                  require([
                    "@/views/projectTaxi/businessReport/vehicleOnlineRate/vehicleOnlineRate.vue"
                  ], resolve),
                meta: {
                  title: "车辆在线率统计"
                }
              },
              {
                path: "/projectTaxi/equipmentStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/equipmentStatistics/equipmentStatistics.vue"
                  ], resolve),
                meta: {
                  title: "设备故障统计"
                }
              }
            ]
          },
          {
            path: "/projectTaxi/enclosureDefin",
            component: resolve =>
              require([
                "@/views/projectGps/positionManage/enclosureDefin/enclosureDefin.vue"
              ], resolve),
            meta: {
              keepAlive: true,
              title: "位置管理"
            }
          },
          {
            path: "/projectTaxi/videoTest",
            component: resolve =>
              require(["@/views/projectRecord/video/video.vue"], resolve),
            meta: {
              title: "视频服务"
            }
          }
        ]
      },
      /** 公交路由 */
      {
        path: "/projectBus",
        name: "",
        component: resolve => require(["@/views/layout/Home.vue"], resolve),
        meta: {
          title: ""
        },
        children: [
          {
            path: "/projectBus",
            redirect: "/projectBus/dashboard"
          },
          // {
          //   path: "/projectBus/dashboard",
          //   component: resolve =>
          //     require(["@/views/projectBus/dashboard/dashboard.vue"], resolve),
          //   meta: {
          //     title: "数据看板"
          //   }
          // },
          {
            path: "/projectBus/dashboard",
            component: resolve =>
              require(["@/views/projectBus/dashboard/dataV.vue"], resolve),
            meta: {
              title: "数据大屏"
            }
          },
          {
            path: "/projectBus/dispatchScreenTroubleshooting",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "调度屏到站排查"
            },
            children: [
              {
                path: "/projectBus/arrivalExceptionReport",
                component: resolve =>
                  require([
                    "@/views/projectBus/dispatchScreenTroubleshooting/arrivalExceptionReport.vue"
                  ], resolve),
                meta: {
                  title: "到站异常报表"
                }
              }
            ]
          },
          {
            path: "/projectBus/oprateManagement",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "运营管理"
            },
            children: [
              {
                path: "/projectBus/schedulingPlatform",
                component: resolve =>
                  require([
                    "@/views/projectBus/operationManagement/schedulingPlatform/schedulingPlatform.vue"
                  ], resolve),
                meta: {
                  title: "实时调度"
                }
              },
              {
                path: "/projectBus/RealTimeSchedulingNew",
                component: resolve =>
                  require([
                    "@/views/projectBus/operationManagement/RealTimeScheduling/RealTimeSchedulingNew.vue"
                  ], resolve),
                meta: {
                  title: "新实时调度2.0"
                }
              },
              {
                path: "/projectBus/powerfulRealTimeScheduling",
                component: resolve =>
                  require([
                    "@/views/projectBus/operationManagement/RealTimeScheduling/powerfulRealTimeScheduling.vue"
                  ], resolve),
                meta: {
                  title: "实时调度(适配版)"
                }
              },
              {
                path: "/projectBus/confirmationScheduling",
                component: resolve =>
                  require([
                    "@/views/projectBus/operationManagement/RealTimeScheduling/confirmationScheduling.vue"
                  ], resolve),
                meta: {
                  title: "线路排班信息确认 "
                }
              },
              {
                path: "/projectBus/RealTimeScheduling",
                component: resolve =>
                  require([
                    "@/views/projectBus/operationManagement/RealTimeScheduling/RealTimeScheduling.vue"
                  ], resolve),
                meta: {
                  title: "新实时调度"
                }
              },
              {
                path: "/projectBus/electronicMap",
                component: resolve =>
                  require([
                    "@/views/projectBus/operationManagement/electronicMap/electronicMap.vue"
                  ], resolve),
                meta: {
                  title: "电子地图"
                }
              },
              {
                path: "/projectBus/electronicMapNew",
                component: resolve =>
                  require([
                    "@/views/projectBus/operationManagement/electronicMap/electronicMapNew.vue"
                  ], resolve),
                meta: {
                  title: "电子地图2.0"
                }
              },
              {
                path: "/projectBus/simulateMap",
                component: resolve =>
                  require([
                    "@/views/projectBus/operationManagement/simulateMap/simulateMap.vue"
                  ], resolve),
                meta: {
                  title: "模拟地图"
                }
              },
              {
                path: "/projectBus/routeMaking",
                component: resolve =>
                  require([
                    "@/views/projectBus/operationManagement/routeMaking/routeMaking.vue"
                  ], resolve),
                meta: {
                  title: "路线制作"
                }
              },
              {
                path: "/projectBus/vehicleRunningRecord",
                component: resolve =>
                  require([
                    "@/views/projectBus/statisticalReports/vehicleRunningRecord/vehicleRunningRecord.vue"
                  ], resolve),
                meta: {
                  title: "车辆行车记录"
                }
              },
              {
                path: "/projectBus/vehicleRunningRecordShow",
                component: resolve =>
                  require([
                    "@/views/projectBus/statisticalReports/vehicleRunningRecord/vehicleRunningRecordShow.vue"
                  ], resolve),
                meta: {
                  title: "车辆行车记录(新)"
                }
              },
              {
                path: "/projectBus/stationCheckCar",
                component: resolve =>
                  require([
                    "@/views/projectBus/operationManagement/stationCheckCar/stationCheckCar.vue"
                  ], resolve),
                meta: {
                  title: "站点查车"
                }
              }
            ]
          },
          {
            path: "/projectBus/alarmManagement",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "车辆监控"
            },
            children: [
              {
                path: "/projectBus/alarmDeal",
                component: resolve =>
                  require(["@/views/monitor/alarmDeal/alarmDeal.vue"], resolve),
                meta: {
                  title: "报警处理"
                }
              },
              {
                path: "/projectBus/alarmSetting",
                component: resolve =>
                  require([
                    "@/views/monitor/alarmSetting/alarmSetting.vue"
                  ], resolve),
                meta: {
                  title: "报警配置"
                }
              },
              {
                path: "/projectBus/newAlarmSettingEdeal",
                component: resolve =>
                  require([
                    "@/views/monitor/alarmSetting/newAlarmSettingEdeal.vue"
                  ], resolve),
                meta: {
                  title: "新报警处理"
                }
              },
              {
                path: "/projectBus/alarmQuery",
                component: resolve =>
                  require([
                    "@/views/monitor/alarmDeal/alarmQuery.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "报警查询"
                }
              },
              {
                path: "/projectBus/monitoring",
                component: resolve =>
                  require([
                    "@/views/monitor/monitoring/monitoring.vue"
                  ], resolve),
                meta: {
                  title: "综合监控"
                }
              },
              {
                path: "/projectBus/monitoringNew",
                component: resolve =>
                  require([
                    "@/views/monitor/monitoring/monitoringNew.vue"
                  ], resolve),
                meta: {
                  title: "综合监控（自建）"
                }
              },
              {
                path: "/projectBus/timedVoiceDistribution",
                component: resolve =>
                  require([
                    "@/views/projectGps/timedVoiceDistribution/timedVoiceDistribution.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "定时下发语音"
                }
              }
            ]
          },

          {
            path: "/projectBus/schedulingPlan",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "排班计划"
            },
            children: [
              {
                path: "/projectBus/paramsManage",
                component: resolve =>
                  require([
                    "@/views/projectBus/schedulingPlan/paramsManage/paramsManage.vue"
                  ], resolve),
                meta: {
                  title: "运营参数"
                }
              },
              {
                path: "/projectBus/projectMange",
                component: resolve =>
                  require([
                    "@/views/projectBus/schedulingPlan/projectMange/projectMange.vue"
                  ], resolve),
                meta: {
                  title: "运营方案"
                }
              },
              {
                path: "/projectBus/projectMangeNew",
                component: resolve =>
                  require([
                    "@/views/projectBus/schedulingPlan/projectMange/projectMangeNew.vue"
                  ], resolve),
                meta: {
                  title: "运营方案2.0"
                }
              },
              {
                path: "/projectBus/schedulingManage",
                component: resolve =>
                  require([
                    "@/views/projectBus/schedulingPlan/schedulingManage/schedulingManage.vue"
                  ], resolve),
                meta: {
                  title: "计划管理"
                }
              },
              {
                path: "/projectBus/teamManagement",
                component: resolve =>
                  require([
                    "@/views/projectBus/schedulingPlan/teamManagement/teamManagement.vue"
                  ], resolve),
                meta: {
                  title: "班组设置"
                }
              },
              {
                path: "/projectBus/departureSdchedule",
                component: resolve =>
                  require([
                    "@/views/projectBus/schedulingPlan/departureSdchedule/departureSdchedule.vue"
                  ], resolve),
                meta: {
                  title: "发车时刻表"
                }
              },
              {
                path: "/projectBus/departureSdcheduleNew",
                component: resolve =>
                  require([
                    "@/views/projectBus/schedulingPlan/departureSdchedule/departureSdcheduleNew.vue"
                  ], resolve),
                meta: {
                  title: "发车时刻表2.0"
                }
              }
            ]
          },
          {
            path: "/projectBus/videoTest",
            component: resolve =>
              require(["@/views/projectRecord/video/video.vue"], resolve),
            meta: {
              title: "视频服务"
            }
          },
          {
            path: "/projectBus/businessReport",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "业务报表"
            },
            children: [
              {
                path: "/projectBus/equipmentStatistics",
                component: resolve =>
                  require([
                    "@/views/projectBus/businessReports/equipmentStatistics/equipmentStatistics.vue"
                  ], resolve),
                meta: {
                  title: "调度屏故障统计"
                }
              },
              {
                path: "/projectBus/equipmentStatisticsAdas",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/equipmentStatistics/equipmentStatistics.vue"
                  ], resolve),
                meta: {
                  title: "adas故障统计"
                }
              },
              {
                path: "/projectBus/dispatchStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/otherReport/dispatchStatistics/dispatchStatistics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "车辆调度统计"
                }
              },
              {
                path: "/projectBus/timeSheetDriver",
                component: resolve =>
                  require([
                    "@/views/projectRecord/businessAnalysis/timeSheetDriver.vue"
                  ], resolve),
                meta: {
                  title: "司机考勤表"
                }
              },
              {
                path: "/projectBus/timeSheetDriverNew",
                component: resolve =>
                  require([
                    "@/views/projectRecord/businessAnalysis/timeSheetDriverNew.vue"
                  ], resolve),
                meta: {
                  title: "司机考勤表(新)"
                }
              },
              {
                path: "/projectBus/accountSheetDriver",
                component: resolve =>
                  require([
                    "@/views/projectRecord/businessAnalysis/accountSheetDriver.vue"
                  ], resolve),
                meta: {
                  title: "司机结算表"
                }
              },
              {
                path: "/projectBus/accountSheetDriverNew",
                component: resolve =>
                  require([
                    "@/views/projectRecord/businessAnalysis/accountSheetDriverNew.vue"
                  ], resolve),
                meta: {
                  title: "司机结算表(新)"
                }
              },
              {
                path: "/projectBus/timeSheetBus",
                component: resolve =>
                  require([
                    "@/views/projectRecord/businessAnalysis/timeSheetBus.vue"
                  ], resolve),
                meta: {
                  title: "车辆考勤表"
                }
              },
              {
                path: "/projectBus/accountSheetBus",
                component: resolve =>
                  require([
                    "@/views/projectRecord/businessAnalysis/accountSheetBus.vue"
                  ], resolve),
                meta: {
                  title: "车辆结算表"
                }
              }
            ]
          },
          //统计报表--暂时没有用
          {
            path: "/projectBus/statisticalReports",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "统计报表"
            },
            children: [
              {
                path: "/projectBus/vehicleRunningRecord",
                component: resolve =>
                  require([
                    "@/views/projectBus/statisticalReports/vehicleRunningRecord/vehicleRunningRecord.vue"
                  ], resolve),
                meta: {
                  title: "车辆行车记录"
                }
              }
            ]
          }
        ]
      },
      /** gps路由 */
      {
        path: "/projectGps",
        name: "",
        component: resolve => require(["@/views/layout/Home.vue"], resolve),
        meta: {
          title: ""
        },
        children: [
          {
            path: "/projectGps",
            redirect: "/projectGps/dashboard"
          },
          {
            path: "/projectGps/dashboard",
            component: resolve =>
              require(["@/views/projectGps/dashboard/dashboard.vue"], resolve),
            meta: {
              title: "数据看板"
            }
          },
          {
            path: "/projectGps/monitoring",
            component: resolve =>
              require(["@/views/monitor/monitoring/monitoring.vue"], resolve),
            meta: {
              title: "运营监控"
            }
          },
          {
            path: "/projectGps/monitoringNew",
            component: resolve =>
              require([
                "@/views/monitor/monitoring/monitoringNew.vue"
              ], resolve),
            meta: {
              title: "运营监控（自建）"
            }
          },
          {
            path: "/projectGps/alarmManagement",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "报警管理"
            },
            children: [
              {
                path: "/projectGps/alarmDeal",
                component: resolve =>
                  require(["@/views/monitor/alarmDeal/alarmDeal.vue"], resolve),
                meta: {
                  title: "报警处理"
                }
              },
              {
                path: "/projectGps/alarmSetting",
                component: resolve =>
                  require([
                    "@/views/monitor/alarmSetting/alarmSetting.vue"
                  ], resolve),
                meta: {
                  title: "报警配置"
                }
              },
              {
                path: "/projectGps/newAlarmSettingEdeal",
                component: resolve =>
                  require([
                    "@/views/monitor/alarmSetting/newAlarmSettingEdeal.vue"
                  ], resolve),
                meta: {
                  title: "新报警处理"
                }
              },
              {
                path: "/projectGps/alarmReport",
                component: resolve =>
                  require([
                    "@/views/monitor/alarmSetting/alarmReport.vue"
                  ], resolve),
                meta: {
                  title: "报警报备"
                }
              },
              {
                path: "/projectGps/mergeAlarmStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/alertReport/mergeAlarmStatistics/mergeAlarmStatistics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "报警合并统计"
                }
              }
            ]
          },
          {
            path: "/projectGps/positionManage",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "位置管理"
            },
            children: [
              /** 4-1 位置管理positionManage_区域围栏管理 */
              {
                path: "/projectGps/enclosureDefin",
                component: resolve =>
                  require([
                    "@/views/projectGps/positionManage/enclosureDefin/enclosureDefin.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "区域围栏管理"
                }
              },

              /** 4-2 位置管理_风险源管理 */
              {
                path: "/projectGps/dangerSource",
                component: resolve =>
                  require([
                    "@/views/projectGps/positionManage/dangerSource/dangerSource.vue"
                  ], resolve),
                meta: {
                  title: "风险源管理"
                }
              },
              /** 4-3 位置管理_车辆绑定区域查询 */
              {
                path: "/projectGps/vehicleBinding",
                component: resolve =>
                  require([
                    "@/views/projectGps/positionManage/vehicleBinding/vehicleBinding.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "车辆绑定区域查询"
                }
              },
              {
                path: "/projectGps/areaFenceAlarmSetting",
                component: resolve =>
                  require([
                    "@/views/projectGps/positionManage/areaFenceAlarmSetting/areaFenceAlarmSetting.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "区域围栏报警设置"
                }
              }
            ]
          },
          {
            path: "/projectGps/operation",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "运营管理"
            },
            children: [
              /** 5-1 运营管理_旅游客车行程 */
              {
                path: "/projectGps/tourismStatistics",
                name: "tourismStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/operationManagement/tourismStatistics/tourismStatistics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "旅游客车行程"
                }
              },
              /** 5-2 运营管理_五不出站管理 */
              {
                path: "/projectGps/serchFivePoint",
                name: "serchFivePoint",
                component: resolve =>
                  require([
                    "@/views/projectGps/operationManagement/serchFivePoint/serchFivePoint.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "五不出站管理"
                }
              },
              /** 5-3 运营管理_危险品运输管理 */
              {
                path: "/projectGps/dangerousGoodsManage",
                name: "dangerousGoodsManage",
                component: resolve =>
                  require([
                    "@/views/projectGps/operationManagement/dangerousGoodsManage/dangerousGoodsManage.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "危险品运输管理"
                }
              },
              /** 5-3 运营管理_监控中心通知管理 */
              {
                path: "/projectGps/monitoringNotificationManaga",
                name: "monitoringNotificationManaga",
                component: resolve =>
                  require([
                    "@/views/projectGps/operationManagement/monitoringNotificationManaga/monitoringNotificationManaga.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "监控中心通知管理"
                }
              }
            ]
          },
          {
            path: "/projectGps/business",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "行驶统计"
            },
            children: [
              /** 6-1 行驶统计-里程、轨迹统计 */
              {
                path: "/projectGps/mileageStatistics",
                name: "mileageStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/mileageStatistics/mileageStatistics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "里程轨迹统计"
                }
              },
              /** 6-2 行驶统计-车辆在线率 */
              {
                path: "/projectGps/carOnlineProbability",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/carOnlineProbability/carOnlineProbability.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "车辆在线率统计"
                }
              },
              /** 6-3 行驶统计-离线车辆统计 */
              {
                path: "/projectGps/outlineStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/outlineStatistics/outlineStatistics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "离线车辆统计"
                }
              },
              /** 6-4 行驶统计-定位统计 */
              {
                path: "/projectGps/locationStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/locationStatistics/locationStatistics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "定位统计"
                }
              },
              /** 6-5 行驶统计-登签退签统计 */
              {
                path: "/projectGps/signUp",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/signUp/signUp.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "登签退签统计"
                }
              },
              /** 6-6 行驶统计- IC卡使用统计*/
              {
                path: "/projectGps/ICStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/ICStatistics/ICStatistics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "IC卡使用统计"
                }
              },
              /** 6-6 行驶统计-设备故障统计 */
              {
                path: "/projectGps/equipmentStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/equipmentStatistics/equipmentStatistics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "设备故障统计"
                }
              },
              /** 6-7 行驶统计-漂移车辆统计 */
              {
                path: "/projectGps/driftStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/driftStatistics/driftStatistics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "漂移车辆统计"
                }
              },
              /** 6-8 行驶统计-驾驶员驾驶时长统计 */
              {
                path: "/projectGps/hourLengthStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/hourLengthStatistics/hourLengthStatistics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "驾驶员驾驶时长统计"
                }
              },
              /** 6-9 行驶统计-报警合并车辆在线率统计 */
              {
                path: "/projectGps/spotOnlineStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/spotOnlineStatistics/spotOnlineStatistics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "车辆在线率"
                }
              },
              /** 6-9 行驶统计-历史数据连续性统计 */
              {
                path: "/projectGps/HistoricalContinuityData",
                component: resolve =>
                  require([
                    "@/views/projectGps/drivingStatistics/spotOnlineStatistics/HistoricalContinuityData.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "历史数据连续性"
                }
              },              
            ]
          },
          {
            path: "/projectGps/alertReport",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "报警统计"
            },
            children: [
              /** 7-1 报警统计_超速报警统计 */
              {
                path: "/projectGps/overSpeed",
                component: resolve =>
                  require([
                    "@/views/projectGps/alertReport/overSpeed/overSpeed.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "超速报警统计"
                }
              },
              /** 7-2 报警统计_疲劳驾驶 */
              {
                path: "/projectGps/fatigueDriving",
                component: resolve =>
                  require([
                    "@/views/projectGps/alertReport/fatigueDriving/fatigueDriving.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "疲劳驾驶统计"
                }
              },
              /** 7-3 报警统计_车辆报警分析*/
              {
                path: "/projectGps/vechileAlarm",
                component: resolve =>
                  require([
                    "@/views/projectGps/alertReport/vechileAlarm/vechileAlarm.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "车辆报警分析"
                }
              },
              /** 7-4 报警统计_驾驶员行为分析 */
              {
                path: "/projectGps/driverScore",
                component: resolve =>
                  require([
                    "@/views/projectGps/alertReport/driverScore/driverScore.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "驾驶员行为分析"
                }
              },
              /** 7-5 报警统计_报警次数统计 */
              {
                path: "/projectGps/alarmsNumber",
                component: resolve =>
                  require([
                    "@/views/projectGps/alertReport/alarmsNumber/alarmsNumber.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "报警次数统计"
                }
              },
              {
                path: "/projectGps/reportAttachment",
                component: resolve =>
                  require([
                    "@/views/projectGps/alertReport/reportAttachment/reportAttachment.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "报警附件完整率"
                }
              },
              {
                path: "/projectGps/alarmQuery",
                component: resolve =>
                  require([
                    "@/views/monitor/alarmDeal/alarmQuery.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "报警查询"
                }
              },
              {
                path: "/projectGps/alarmDetails",
                component: resolve =>
                  require([
                    "@/views/projectGps/alertReport/alarmDetails/alarmDetails.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "报警明细查询"
                }
              },
              {
                path: "/projectGps/OilConsumption",
                component: resolve =>
                  require([
                    "@/views/projectGps/alertReport/OilConsumption/OilConsumption.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "油量查询"
                }
              },
              //报警合并
              {
                path: "/projectGps/speedStatics",
                component: resolve =>
                  require([
                    "@/views/projectGps/alertReport/combinedAlarmmAtatistics/speedStatics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "超速报警统计-报警合并"
                }
              },
              {
                path: "/projectGps/fatiguedStatics",
                component: resolve =>
                  require([
                    "@/views/projectGps/alertReport/combinedAlarmmAtatistics/fatiguedStatics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "疲劳报警统计-报警合并"
                }
              },
            ]
          },
          {
            path: "/projectGps/otherReport",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "管理行为分析"
            },
            children: [
              /** 8-10 管理行为分析_抽查车辆 */
              {
                path: "/projectGps/checkVehicle",
                component: resolve =>
                  require([
                    "@/views/projectGps/otherReport/checkVehicle/checkVehicle.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "车辆抽查统计"
                }
              },
              /** 8-11 管理行为分析_调度统计 */
              {
                path: "/projectGps/dispatchStatistics",
                component: resolve =>
                  require([
                    "@/views/projectGps/otherReport/dispatchStatistics/dispatchStatistics.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "车辆调度统计"
                }
              },
              /** 8-12 管理行为分析_用户登录统计 */
              {
                path: "/projectGps/userLoginStatis",
                component: resolve =>
                  require([
                    "@/views/projectGps/otherReport/userLoginStatis/userLoginStatis.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "用户登录统计"
                }
              },
              //用于后端排查轨迹漂移的问题测试页面
              {
                path: "/projectGps/trackMapHistory",
                component: resolve =>
                  require([
                    "@/views/projectGps/otherReport/trackMapHistory/trackMapHistory.vue"
                  ], resolve),
                meta: {
                  keepAlive: true,
                  title: "轨迹排查问题"
                }
              }
            ]
          },
          {
            path: "/projectGps/videoTest",
            component: resolve =>
              require(["@/views/projectRecord/video/video.vue"], resolve),
            meta: {
              title: "视频服务"
            }
          },
          {
            path: "/projectGps/dynamicAlarm",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "报警日监分析表"
            },
            children: [
              {
                path: "/projectGps/dynamicMonitoringAlarm",
                component: resolve =>
                  require([
                    "@/views/projectGps/dynamicMonitoringAlarm/dynamicMonitoringAlarm.vue"
                  ], resolve),
                meta: {
                  title: "日监统计分析表"
                }
              },
              {
                path: "/projectGps/monthAnalysis",
                component: resolve =>
                  require([
                    "@/views/projectGps/dynamicMonitoringAlarm/monthAnalysis.vue"
                  ], resolve),
                meta: {
                  title: "月度统计分析表"
                }
              }
            ]
          },
          {
            path: "/projectGps/platformCheck",
            component: resolve =>
              require([
                "@/views/projectGps/platformCheck/platformCheck.vue"
              ], resolve),
            meta: {
              keepAlive: true,
              title: "平台查岗"
            }
          },
          {
            path: "/projectGps/timedVoiceDistribution",
            component: resolve =>
              require([
                "@/views/projectGps/timedVoiceDistribution/timedVoiceDistribution.vue"
              ], resolve),
            meta: {
              keepAlive: true,
              title: "定时下发语音"
            }
          }
        ]
      },
      /** 公共资源 */
      {
        path: "",
        component: resolve => require(["@/views/layout/Home.vue"], resolve),
        meta: {
          title: ""
        },
        children: [
          {
            path: "/projectResourse",
            redirect: "/projectResourse/basicData"
          },
          {
            path: "/projectResourse/chartsManage",
            component: resolve =>
              require([
                "@/views/projectResourse/chartsManage/index.vue"
              ], resolve),
            meta: {
              title: "图表库管理"
            }
          },
          {
            path: "/projectResourse/basicData",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "基础信息管理"
            },
            children: [
              {
                path: "/projectResourse/vehicleManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/basicData/vehicleManagement/vehicleManagement.vue"
                  ], resolve),
                meta: {
                  title: "车辆管理"
                }
              },
              {
                path: "/projectResourse/vehicleManagement821",
                component: resolve =>
                  require([
                    "@/views/projectResourse/basicData/vehicleManagement/vehicleManagement821.vue"
                  ], resolve),
                meta: {
                  title: "车辆管理"
                }
              },
              {
                path: "/projectResourse/vehicleManagementSanitation",
                component: resolve =>
                  require([
                    "@/views/projectResourse/basicData/vehicleManagement/vehicleManagementSanitation.vue"
                  ], resolve),
                meta: {
                  title: "车辆管理"
                }
              },
              {
                path: "/projectResourse/dispatchPanel",
                component: resolve =>
                  require([
                    "@/views/projectResourse/basicData/dispatchPanel/dispatchPanel.vue"
                  ], resolve),
                meta: {
                  title: "终端升级"
                }
              },
              {
                path: "/projectResourse/driverManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/basicData/driverManagement/driverManagement.vue"
                  ], resolve),
                meta: {
                  title: "驾驶员管理"
                }
              },
              {
                path: "/projectResourse/terminalManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/basicData/terminalManagement/terminalManagement.vue"
                  ], resolve),
                meta: {
                  title: "终端管理"
                }
              },
              {
                path: "/projectResourse/terminalAttribute",
                component: resolve =>
                  require([
                    "@/views/projectResourse/basicData/terminalManagement/terminalAttribute.vue"
                  ], resolve),
                meta: {
                  title: "终端属性"
                }
              },
              {
                path: "/projectResourse/ICCardManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/basicData/ICCardManagement/ICCardManagement.vue"
                  ], resolve),
                meta: {
                  title: "IC卡管理"
                }
              },
              {
                path: "/projectResourse/yardManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/basicData/yardMange/yardMange.vue"
                  ], resolve),
                meta: {
                  title: "车场管理"
                }
              },
              {
                path: "/projectResourse/lineManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/basicData/lineManage/lineManage.vue"
                  ], resolve),
                meta: {
                  title: "线路管理"
                }
              },
              {
                path: "/projectResourse/siteManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/basicData/siteMange/siteMange.vue"
                  ], resolve),
                meta: {
                  title: "站点管理"
                }
              }
            ]
          },
          {
            path: "/projectResourse/SystemSetting",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "系统设置"
            },
            children: [
              {
                path: "/projectResourse/businessManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/systemSettings/businessManagement/businessManagement.vue"
                  ], resolve),
                meta: {
                  title: "企业管理"
                }
              },
              {
                path: "/projectResourse/userManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/systemSettings/userManagement/userManagement.vue"
                  ], resolve),
                meta: {
                  title: "用户管理"
                }
              },
              {
                path: "/projectResourse/userGroupManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/systemSettings/userGroupManagement/userGroupManagement.vue"
                  ], resolve),
                meta: {
                  title: "用户组管理"
                }
              },
              {
                path: "/projectResourse/roleManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/systemSettings/roleManagement/roleManagement.vue"
                  ], resolve),
                meta: {
                  title: "角色管理"
                }
              },
              {
                path: "/projectResourse/authorizationManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/systemSettings/authorizationManagement/authorizationManagement.vue"
                  ], resolve),
                meta: {
                  title: "权限管理"
                }
              },
              {
                path: "/projectResourse/menuManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/systemSettings/menuManagement/menuManagement.vue"
                  ], resolve),
                meta: {
                  title: "菜单管理"
                }
              },
              {
                path: "/projectResourse/dataDictionary",
                component: resolve =>
                  require([
                    "@/views/projectResourse/systemSettings/dataDictionary/dataDictionary.vue"
                  ], resolve),
                meta: {
                  title: "数据字典"
                }
              },
              {
                path: "/projectResourse/equipmentModel",
                component: resolve =>
                  require([
                    "@/views/projectResourse/systemSettings/equipmentModel/equipmentModel.vue"
                  ], resolve),
                meta: {
                  title: "设备型号管理"
                }
              },
              {
                path: "/projectResourse/accessManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/systemSettings/accessManagement/accessManagement.vue"
                  ], resolve),
                meta: {
                  title: "准入管理"
                }
              },
              {
                path: "/projectResourse/upgradeManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/systemSettings/upgradeManagement/upgradeManagement.vue"
                  ], resolve),
                meta: {
                  title: "升级包管理"
                }
              },
              {
                path: "/projectResourse/approvalPermission",
                component: resolve =>
                  require([
                    "@/views/projectResourse/systemSettings/approvePermission/approvePermission.vue"
                  ], resolve),
                meta: {
                  title: "审批权限管理"
                }
              },
              {
                path: "/projectResourse/forwardConfiguration",
                component: resolve =>
                  require([
                    "@/views/projectResourse/systemSettings/forwardConfiguration/forwardConfiguration.vue"
                  ], resolve),
                meta: {
                  title: "华油转发配置"
                }
              }
            ]
          },
          {
            path: "/projectResourse/log",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "日志管理"
            },
            children: [
              {
                path: "/projectResourse/logIn",
                component: resolve =>
                  require([
                    "@/views/projectResourse/logManagement/logIn/logIn.vue"
                  ], resolve),
                meta: {
                  title: "登录日志"
                }
              },
              {
                path: "/projectResourse/operationLog",
                component: resolve =>
                  require([
                    "@/views/projectResourse/logManagement/operationLog/operationLog.vue"
                  ], resolve),
                meta: {
                  title: "操作日志"
                }
              },
              {
                path: "/projectResourse/operationStatistics",
                component: resolve =>
                  require([
                    "@/views/projectResourse/logManagement/operationStatistics/operationStatistics.vue"
                  ], resolve),
                meta: {
                  title: "操作统计"
                }
              },
              {
                path: "/projectResourse/abnormalTerminalLog",
                component: resolve =>
                  require([
                    "@/views/projectResourse/logManagement/abnormalTerminalLog/abnormalTerminalLog.vue"
                  ], resolve),
                meta: {
                  title: "异常终端日志"
                }
              }
            ]
          },
          {
            path: "/projectResourse/mocha",
            component: resolve =>
              require(["@/views/projectResourse/mocha/mocha.vue"], resolve),
            meta: {
              title: "运维管理"
            }
          },
          {
            path: "/projectResourse/data",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "数据转发管理"
            },
            children: [
              {
                path: "/projectResourse/thirdPartyPlatform",
                component: resolve =>
                  require([
                    "@/views/projectResourse/dataForwardingManagement/thirdPartyPlatform.vue"
                  ], resolve),
                meta: {
                  title: "第三方平台配置管理"
                }
              },
              {
                path: "/projectResourse/dataCategoriesManagement",
                component: resolve =>
                  require([
                    "@/views/projectResourse/dataForwardingManagement/dataCategoriesManagement.vue"
                  ], resolve),
                meta: {
                  title: "数据类别管理"
                }
              }
            ]
          }
        ]
      },
      /** 经营分析报表 */
      {
        path: "/projectRecord",
        name: "",
        component: resolve => require(["@/views/layout/Home.vue"], resolve),
        meta: {
          title: ""
        },
        children: [
          {
            path: "/projectRecord",
            redirect: "/projectRecord/businessAnalysis"
          },
          {
            path: "/projectRecord/businessAnalysis",
            component: resolve =>
              require(["@/components/basicDataMange/index.vue"], resolve),
            meta: {
              title: "统计报表"
            },
            children: [
              {
                path: "/projectRecord/timeSheetDriver",
                component: resolve =>
                  require([
                    "@/views/projectRecord/businessAnalysis/timeSheetDriver.vue"
                  ], resolve),
                meta: {
                  title: "司机考勤表"
                }
              },
              {
                path: "/projectRecord/accountSheetDriver",
                component: resolve =>
                  require([
                    "@/views/projectRecord/businessAnalysis/accountSheetDriver.vue"
                  ], resolve),
                meta: {
                  title: "司机结算表"
                }
              },
              {
                path: "/projectRecord/timeSheetBus",
                component: resolve =>
                  require([
                    "@/views/projectRecord/businessAnalysis/timeSheetBus.vue"
                  ], resolve),
                meta: {
                  title: "车辆考勤表"
                }
              },
              {
                path: "/projectRecord/accountSheetBus",
                component: resolve =>
                  require([
                    "@/views/projectRecord/businessAnalysis/accountSheetBus.vue"
                  ], resolve),
                meta: {
                  title: "车辆结算表"
                }
              }
            ]
          }
        ]
      }
    ]
  }
];
console.log("qiankun name", window.ProjectName);
let defalutBase = "/";
if(window.ProjectName==="sanitation"){
  defalutBase = "/sanitation/projectSanitation/child-app/";
}else if(window.ProjectName==="kkx"){
  defalutBase = "/kkx/projectKKX/child-app/";
}else{
  defalutBase = "/821/projectScheduling/child-app/";
}
const router = new Router({
  base: window.__POWERED_BY_QIANKUN__ ? defalutBase : "/",
  mode: window.__POWERED_BY_QIANKUN__ ? "history" : "hash",
  routes
});

const addStorageEvent = function(k, val) {
  const newStorageEvent = document.createEvent("StorageEvent");
  localStorage.setItem(k, val);
  // 初始化创建的事件
  newStorageEvent.initStorageEvent(
    "setItem",
    false,
    false,
    k,
    null,
    val,
    null,
    null
  );
  // 派发对象
  window.dispatchEvent(newStorageEvent);
};

// 全局路由守卫
NProgress.configure({
  showSpinner: false
});
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.path == "/login") {
    next();
  } else {
    var token =
      sessionStorage.getItem("token") || localStorage.getItem("token");
    //如果没登录,都导向登录页
    if (!token) {
      if (to.path !== "/login") {
        next({
          path: "/login"
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});
router.afterEach(to => {
  if (window.__POWERED_BY_QIANKUN__) {
    if (window.ProjectName === "sanitation") {
      addStorageEvent("YH_BF_THEME", "theme-project-taxi");
    } else {
      addStorageEvent("YH_BF_THEME", "theme-project-gps");
      if (to.path.indexOf("projectGps") > -1) {
        sessionStorage.setItem("system", 0);
      }
      if (to.path.indexOf("projectTaxi") > -1) {
        sessionStorage.setItem("system", 1);
      }
      if (to.path.indexOf("projectBus") > -1) {
        sessionStorage.setItem("system", 2);
      }
      if (to.path.indexOf("projectResourse") > -1) {
        sessionStorage.setItem("system", 5);
      }
      if (to.path.indexOf("projectRecord") > -1) {
        sessionStorage.setItem("system", 0);
      }
    }
  } else {
    if (to.path.indexOf("projectGps") > -1) {
      addStorageEvent("YH_BF_THEME", "theme-project-gps");
      sessionStorage.setItem("system", 0);
    }
    if (to.path.indexOf("projectTaxi") > -1) {
      addStorageEvent("YH_BF_THEME", "theme-project-taxi");
      sessionStorage.setItem("system", 1);
    }
    if (to.path.indexOf("projectBus") > -1) {
      addStorageEvent("YH_BF_THEME", "theme-project-bus");
      sessionStorage.setItem("system", 2);
    }
    if (to.path.indexOf("projectResourse") > -1) {
      addStorageEvent("YH_BF_THEME", "theme-project-resourse");
      sessionStorage.setItem("system", 5);
    }
    if (to.path.indexOf("projectRecord") > -1) {
      addStorageEvent("YH_BF_THEME", "theme-project-record"); //根据路由路径配置使用主题颜色
      sessionStorage.setItem("system", 0);
    }
  }
  // if (to.path.indexOf('projectGps') > -1) {
  //     addStorageEvent('YH_BF_THEME', 'theme-project-gps')
  //     sessionStorage.setItem("system", 0)
  // }
  // if (to.path.indexOf('projectTaxi') > -1) {
  //     addStorageEvent('YH_BF_THEME', 'theme-project-taxi')
  //     sessionStorage.setItem("system", 1)
  // }
  // if (to.path.indexOf('projectBus') > -1) {
  //     addStorageEvent('YH_BF_THEME', 'theme-project-bus')
  //     sessionStorage.setItem("system", 2)
  // }
  // if (to.path.indexOf('projectResourse') > -1) {
  //     addStorageEvent('YH_BF_THEME', 'theme-project-resourse')
  //     sessionStorage.setItem("system", 5)
  // }
  // if (to.path.indexOf('projectRecord') > -1) {
  //     addStorageEvent('YH_BF_THEME', 'theme-project-record'); //根据路由路径配置使用主题颜色
  //     sessionStorage.setItem("system", 0)
  // }
  if (to.path) {
    window.sessionStorage.setItem("routeName", to.path.substr(1));
  }
  NProgress.done();
});
export default router;
