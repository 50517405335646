var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-dia", class: { batch: _vm.isBatch } },
    [
      !_vm.fileDialog
        ? _c("div", [
            !_vm.isBatch
              ? _c("div", { staticClass: "scroll" }, [
                  _c(
                    "p",
                    { staticClass: "title header-title" },
                    [
                      _c("span", [_vm._v("报警信息")]),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            disabled:
                              _vm.detailForm.alarmDetailVoList.length == 0,
                            loading: _vm.downLoadStatus
                          },
                          on: { click: _vm.exportDetail }
                        },
                        [_vm._v("导出")]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "information" }, [
                    _c("div", { staticClass: "head" }, [
                      _c("p", [
                        _c("span", [_vm._v("车牌号：")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.detailForm.alarmBaseVO.cph))
                        ])
                      ]),
                      _c("p", [
                        _c("span", [_vm._v("所属车队：")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.detailForm.alarmBaseVO.companyName))
                        ])
                      ]),
                      _c("p", [
                        _c("span", [_vm._v("驾驶员：")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.detailForm.alarmBaseVO.driverName))
                        ])
                      ]),
                      _c("p", [
                        _c("span", [_vm._v("报警类型：")]),
                        _c("span", { staticClass: "sign" }, [
                          _vm._v(_vm._s(_vm.detailForm.alarmBaseVO.alarmName))
                        ])
                      ]),
                      _c("p", [
                        _c("span", [_vm._v("报警等级：")]),
                        _c("span", { staticClass: "sign" }, [
                          _vm._v(
                            _vm._s(
                              _vm.alarmLevelObj[
                                Number(_vm.detailForm.alarmBaseVO.alarmLevel)
                              ]
                            )
                          )
                        ])
                      ]),
                      _c("p", [
                        _c("span", [_vm._v("驾驶证号：")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.detailForm.alarmBaseVO.drivingLicense)
                          )
                        ])
                      ])
                    ]),
                    _c("p", { staticClass: "split-line" }),
                    _c(
                      "div",
                      { staticClass: "body" },
                      [
                        _c(
                          "el-table",
                          {
                            staticClass: "no-blank alarm-table",
                            attrs: {
                              data: _vm.detailForm.alarmDetailVoList,
                              "max-height": "245px"
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "index", label: "序号" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "alarmSignTime",
                                label: "报警时间",
                                width: "160"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "velocity",
                                label: "车速",
                                width: "60"
                              }
                            }),
                            _vm.detailForm.alarmBaseVO.alarmType === 2012
                              ? _c(
                                  "el-table-column",
                                  { attrs: { label: "限速值", width: "80" } },
                                  [
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.detailForm.alarmBaseVO
                                              .limitValue
                                          ) +
                                          " "
                                      )
                                    ]
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.detailForm.alarmBaseVO.alarmType === 2012
                              ? _c("el-table-column", {
                                  attrs: { label: "超速比例", width: "80" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("filterProportion")(
                                                    scope.row.velocity,
                                                    _vm.detailForm.alarmBaseVO
                                                      .limitValue
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2756181470
                                  )
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                prop: "position",
                                label: "经纬度",
                                width: "180",
                                "show-overflow-tooltip": true
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.longitude +
                                                "," +
                                                scope.row.latitude
                                            )
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3012761241
                              )
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "address",
                                label: "位置信息",
                                width: "200",
                                "show-overflow-tooltip": true
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        !scope.row.address
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  cursor: "pointer",
                                                  color: "#336FFE"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.showAdsress(
                                                      scope.row,
                                                      scope.row.longitude,
                                                      scope.row.latitude
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("查看地址")]
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(scope.row.address))
                                            ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3061899579
                              )
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "vehStatus",
                                label: "车辆状态",
                                width: "150",
                                "show-overflow-tooltip": true
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "附件" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        scope.row.alarmSignFileNum
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "sign pointer",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onShowAnnex(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("查看附件")]
                                            )
                                          : _c("span", [_vm._v("-")])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2106389984
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.alarmDetail &&
                              _vm.alarmDetail.disposeStatus !== 2) ||
                            _vm.detailForm.disposeAlarmDataVoList.length !== 0,
                          expression:
                            "\n          (alarmDetail && alarmDetail.disposeStatus !== 2) ||\n          detailForm.disposeAlarmDataVoList.length !== 0\n        "
                        }
                      ],
                      staticClass: "title"
                    },
                    [_vm._v(" 处理记录 ")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.alarmDetail &&
                              _vm.alarmDetail.disposeStatus !== 2) ||
                            _vm.detailForm.disposeAlarmDataVoList.length !== 0,
                          expression:
                            "\n          (alarmDetail && alarmDetail.disposeStatus !== 2) ||\n          detailForm.disposeAlarmDataVoList.length !== 0\n        "
                        }
                      ],
                      staticClass: "record"
                    },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "no-blank",
                          attrs: {
                            data: _vm.detailForm.disposeAlarmDataVoList,
                            "max-height": "146px"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "index", label: "序号" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "operator", label: "处理人姓名" }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "disposeTime",
                              label: "处理时间",
                              width: "180"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "disposeType", label: "处理类型" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.disposeTypeObj[
                                            Number(scope.row.disposeType)
                                          ]
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2678050142
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "disposeWay", label: "处理方式" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.disposeWayObj[
                                            Number(scope.row.disposeWay)
                                          ]
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1795937598
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "disposeDescription",
                              label: "处理内容",
                              "show-overflow-tooltip": true
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.alarmDetail && _vm.alarmDetail.disposeStatus !== 1
              ? _c("div", { staticClass: "fixed" }, [
                  _c("p", { staticClass: "title" }, [_vm._v("报警处理")]),
                  _c(
                    "div",
                    { staticClass: "deal" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "alarmForm",
                          staticClass: "demo-form deal-form",
                          attrs: {
                            inline: true,
                            model: _vm.form,
                            "label-width": "110px",
                            "label-position": "left",
                            rules: _vm.rules
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "处理方式：", prop: "disposeWay" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.changeWay },
                                  model: {
                                    value: _vm.form.disposeWay,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "disposeWay", $$v)
                                    },
                                    expression: "form.disposeWay"
                                  }
                                },
                                _vm._l(_vm.disposeWayList, function(item) {
                                  return _c("el-option", {
                                    key: item.dictCode,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictCode
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm.form.disposeWay === "1"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "文本模板",
                                    prop: "textTemplate"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      on: { change: _vm.selectTextTemplate },
                                      model: {
                                        value: _vm.form.textTemplate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "textTemplate",
                                            $$v
                                          )
                                        },
                                        expression: "form.textTemplate"
                                      }
                                    },
                                    _vm._l(_vm.templateList, function(
                                      item,
                                      key
                                    ) {
                                      return _c("el-option", {
                                        key: key,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.form.disposeWay === "3"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: { label: "电话号码：", prop: "phone" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入手机号" },
                                    model: {
                                      value: _vm.form.phone,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "phone",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.phone"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("el-form-item"),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "处理描述：",
                                prop: "disposeDescription"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入***"
                                },
                                model: {
                                  value: _vm.form.disposeDescription,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "disposeDescription",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.disposeDescription"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.fileDialog
        ? _c("div", [
            _c(
              "div",
              { staticClass: "topleft" },
              [
                _c(
                  "el-carousel",
                  {
                    attrs: {
                      interval: 10000,
                      "indicator-position": "none",
                      trigger: "click",
                      height: "600px"
                    }
                  },
                  _vm._l(_vm.fileForm, function(item) {
                    return _c("el-carousel-item", { key: item.time }, [
                      item.alarmFileType === 0
                        ? _c("div", [
                            _c("img", {
                              attrs: {
                                height: "80%",
                                src: item.alarmFilePath,
                                alt: ""
                              }
                            }),
                            _c("p", [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "el-link el-link--default is-underline",
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      item.alarmFilePath +
                                      "?response-content-type=application/octet-stream",
                                    download: "picture"
                                  }
                                },
                                [
                                  _c("i", { staticClass: "el-icon-download" }),
                                  _c(
                                    "span",
                                    { staticClass: "el-link--inner" },
                                    [_vm._v("下载图片")]
                                  )
                                ]
                              )
                            ])
                          ])
                        : _vm._e(),
                      item.alarmFileType === 2
                        ? _c(
                            "div",
                            [
                              _c("video-player", {
                                ref: "videoPlayer",
                                refInFor: true,
                                staticClass: "video-player vjs-custom-skin",
                                attrs: {
                                  playsinline: true,
                                  options: item.fileNo
                                }
                              }),
                              _c(
                                "p",
                                { staticStyle: { "margin-top": "5px" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "el-link el-link--default is-underline",
                                      attrs: {
                                        target: "_blank",
                                        href:
                                          item.alarmFilePath +
                                          "?response-content-type=application/octet-stream",
                                        download: "video"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-download"
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "el-link--inner" },
                                        [_vm._v("下载视频")]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }