import service from "./refresh";
//接口前缀
const BASE_PREFIX = "/base";

/********************实时监控模块****************/

// 实时更新树
const queryAllNode = data => {
  return service({
    url: `${BASE_PREFIX}/tree/queryAllNode`,
    method: "get",
    data
  });
};

// 车辆树
const getVehicleGroupTree = data => {
  return service({
    url: `${BASE_PREFIX}/tree/queryNode`,
    method: "get",
    data
  });
};

// 模糊搜索下拉框
const searchTree = data => {
  return service({
    url: `${BASE_PREFIX}/tree/search`,
    method: "get",
    data
  });
};

// 综合监控 获取动态车辆位置
const getPostionVhiclePositions = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/vehiclePositions`,
    method: "get",
    data
  });
};

// 综合监控 查询实时警报
const queryRealTimeAlarm = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/realTimeAlarms`,
    method: "get",
    data
  });
};

// 综合监控 查询异常报警
const queryExceptionAlarm = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/exceptionAlarms`,
    method: "get",
    data
  });
};

//实时拍照图片
const querySnapshotImgs = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/getSnapshotImgs`,
    method: "post",
    data
  });
};

// 综合监控 离线提醒表格
const getOfflineRemind = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/offline`,
    method: "get",
    data
  });
};

// 综合监控 树的车辆状态更新
const getVhicleStatus = data => {
  return service({
    url: `${BASE_PREFIX}/tree/online`,
    method: "get",
    data
  });
};

// 轨迹回放列表数据查询
const getHistoryTrack = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/historyTrack`,
    method: "get",
    data
  });
};

// 轨迹回放地图点位查询
const getHistoryTrackView = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/historyTrackView`,
    method: "get",
    data
  });
};
// 轨迹回放地图点位查询(谢2021.7.31)
const getHistoryTrackViewNew = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/historyTrackViewNew`,
    method: "get",
    data
  });
};

//轨迹查询时间区间分割(谢2021.7.29)
const getQueryTimeSection = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/getQueryTimeSection`,
    method: "post",
    data
  });
};
//轨迹回放列表数据查询新(谢2021.7.29)
const gethistoryTrackNew = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/historyTrackNew`,
    method: "get",
    data
  });
};

// 轨迹回放列表总数
const positionCount = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/positionCount`,
    method: "get",
    data
  });
};

// 根据车辆id(v1)或者公司id查询车辆终端信息*(下发指令用)
const queryDeviceByIds = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/devices`,
    method: "get",
    data
  });
};

// 根据终端号查询车辆信息*(下发指令--视频返回用)
const queryVehicleByDeviceNo = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/queryVehicleByDeviceNo`,
    method: "get",
    data
  });
};

/**
 * @description 定时下发语音
 */
// 添加定时文本下发
const saveMessageTaskAPI = data => {
  return service({
    url: `/base/message/task/saveMessageTask`,
    method: "post",
    data
  });
};
// 修改定时文本下发
const updateMessageTaskAPI = data => {
  return service({
    url: `/base/message/task/updateMessageTask`,
    method: "put",
    data
  });
};
// 定时下发文本信息分页
const queryMessageTaskPageAPI = data => {
  return service({
    url: `/base/message/task/queryMessageTaskPage`,
    method: "post",
    data
  });
};

/**
 * 历史视频上传状态列表
 *
 */

const getHistoryVideoList = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/historyVideos`,
    method: "get",
    data
  });
};
//暂停历史视频
const suspendedVideo = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/controlVideo`,
    method: "get",
    data
  });
};

/**
 * 历史视频上传状态列表
 *
 */

const historyVideoCheck = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/historyVideoCheck`,
    method: "post",
    data
  });
};

/**
 * 播放视频模块--通知后台关流(出租车)
 *
 */

const closeVehicleVideo = data => {
  return service({
    url: `${BASE_PREFIX}/vehicleVideo/close`,
    method: "post",
    data
  });
};

/**
 * 车辆选择组件--模糊搜索车辆
 *
 */

const searchVehicleFuzzy = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/queryVehicleByCph?cph=${data}`,
    method: "get"
  });
};

/**
 * 车辆选择组件--模糊搜索车辆
 *
 */

const queryVehicleFuzzy = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/queryVehicleByCph`,
    method: "get",
    data
  });
};

/**车辆选择组件--模糊搜索车辆 谢修改2021.06.23 */

const queryVehicleFuzzyNew = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/queryByCphAndDeviceType`,
    method: "get",
    data
  });
};

/**车辆选择组件--模糊搜索车辆 谢修改20231225----公交2.0 */

const queryVehicleFuzzyNew2 = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/queryByCphAndDeviceTypeNew`,
    method: "get",
    data
  });
};

/**
 * 驾驶员管理--模糊搜索车辆（需要区分主驾副驾，以及车辆是否被绑定）
 *
 */

const queryDriverVehicleFuzzy = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/getVehicles`,
    method: "post",
    data
  });
};

/**
 * 线路管理--模糊搜索线路
 *
 */

const queryLineDetailFuzzy = data => {
  return service({
    url: `/bus/lineDetail/vagueSearchByName`,
    method: "get",
    data
  });
};

/**
 * 线路管理--模糊搜索站点
 *
 */

const querySiteFuzzy = data => {
  return service({
    url: `/bus/station/vagueSearchByName`,
    method: "post",
    data
  });
};

/**
 * 线路管理--模糊搜索车场
 *
 */

const queryYardFuzzy = data => {
  return service({
    url: `/bus/park/vagueSearchByName`,
    method: "post",
    data
  });
};

// 轮询车辆请求
const eventReports = data => {
  return service({
    url: "/bus/dispatchPlatform/eventReports",
    method: "get",
    data
  });
};

// 处理车辆请求
const updateEventReport = data => {
  return service({
    url: "/bus/dispatchPlatform/modifyEvent",
    method: "post",
    data
  });
};

/**
 * checkVideoStreams
 *
 */

const checkVideoStreams = data => {
  return service({
    url: `${BASE_PREFIX}/video/checkVideoStreams`,
    method: "post",
    data
  });
};

/**
 *
 *ws-flv Linux检查流状态
 */
const checkVideoStreamsLinuxNew = data => {
  return service({
    url: `${BASE_PREFIX}/video/checkVideoStreamsLinuxNew`,
    method: "post",
    data
  });
};

/**
 * 添加抽查信息
 *
 */

const addSpotCheck = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/spotCheck`,
    method: "get",
    data
  });
};

// 获取【车辆信息】车辆卡片
const queryVehicleCardById = data => {
  return service({
    url: "/base/monitor/vehicleCard",
    method: "get",
    data
  });
};
//清除区域设置
const deleteVehicleConfig = data => {
  return service({
    url: "/base/enclosure/deleteVehicleConfig",
    method: "delete",
    data
  });
};

export {
  queryMessageTaskPageAPI,
  updateMessageTaskAPI,
  saveMessageTaskAPI,
  queryAllNode,
  getVehicleGroupTree,
  searchTree,
  getPostionVhiclePositions,
  queryRealTimeAlarm,
  queryExceptionAlarm,
  querySnapshotImgs,
  getOfflineRemind,
  getVhicleStatus,
  getHistoryTrack,
  getHistoryTrackView,
  getHistoryTrackViewNew,
  getQueryTimeSection,
  gethistoryTrackNew,
  positionCount,
  queryDeviceByIds,
  queryVehicleByDeviceNo,
  getHistoryVideoList,
  historyVideoCheck,
  closeVehicleVideo,
  searchVehicleFuzzy,
  queryVehicleFuzzy,
  queryVehicleFuzzyNew,
  queryVehicleFuzzyNew2,
  queryDriverVehicleFuzzy,
  queryLineDetailFuzzy,
  querySiteFuzzy,
  queryYardFuzzy,
  eventReports,
  updateEventReport,
  checkVideoStreams,
  addSpotCheck,
  queryVehicleCardById,
  deleteVehicleConfig,
  suspendedVideo,
  checkVideoStreamsLinuxNew
};

/* ----------------- 表格导出及下载模块 ---------------------- */
/**
 * 添加导出文件记录
 *
 */
const addExcelExport = data => {
  let parma = {
    ...data
  };
  delete data.baseUrl;
  return service({
    url: `${BASE_PREFIX}/excelExport/insert`,
    method: "post",
    from: parma.baseUrl,
    data
  });
};

/**
 * 删除导出文件记录
 *
 */
const delExcelExport = data => {
  return service({
    url: `${BASE_PREFIX}/excelExport/delete`,
    method: "post",
    data
  });
};

/**
 * 修改导出文件记录
 *
 */
const updateExcelExport = data => {
  return service({
    url: `${BASE_PREFIX}/excelExport/update`,
    method: "post",
    data
  });
};

/**
 * 分页查询文件导出记录
 *
 */
const queryExcelExportList = data => {
  return service({
    url: `${BASE_PREFIX}/excelExport/queryPageList`,
    method: "post",
    data
  });
};

export {
  addExcelExport,
  delExcelExport,
  updateExcelExport,
  queryExcelExportList
};
/*****************************公交*****************************************/
/**
 * 电子地图 车辆实时信息
 *
 */
// 获取车辆实时定位信息
const getBusInfo = data => {
  return service({
    url: `/bus/electronicMap/queryBusPositions`,
    method: "post",
    data
  });
};

// 根据线路查询车辆信息 -车辆到点站信息
const queryBusPosition = data => {
  return service({
    url: `/bus/simulateMap/queryBusPosition`,
    method: "get",
    data
  });
};

export { getBusInfo, queryBusPosition };
