import moment from 'moment'
import store from '../../store'
import {
  Message
} from 'element-ui'
import AMap from 'AMap'
import getPlace from '@/common/utils/getPlace.js'

const formatDate = time => {
  if (time) {
    const d = new Date(time).valueOf()
    const date = moment(d).format('YYYY-MM-DD HH:mm:ss')
    return date
  } else {
    return ''
  }
}
const formatHour = time => {
  const d = new Date(time).valueOf()
  const date = moment(d).format('HH:mm')
  return date
}

const formatDay = time => {
  const d = new Date(time).valueOf()
  const date = moment(d).format('YYYY-MM-DD')
  return date
}

const formatMonth = time => {
  const d = new Date(time).valueOf()
  const date = moment(d).format('YYYY-MM')
  return date
}

const getXmonthToday =  (type) => {
  // type 0 是当天 -1 是上个月   1是下个月
  var now = new Date(); // 可以传值调式 now = new Date(2019,2,30); 今天是3月30号
  var year = now.getFullYear();//getYear()+1900=getFullYear()
  var month = now.getMonth() + 1;//0-11表示1-12月
  var day = now.getDate(); // 当天日期
  if (parseInt(month) < 10) {
    month = "0" + month;
  }
  if (parseInt(day) < 10) {
    day = "0" + day;
  }
  now = year + '-' + month + '-' + day; // 如果取当月日期可直接 return 返回

  var preMonth = parseInt(month) - 1;
  preMonth = preMonth < 10 ? '0' + preMonth : preMonth; // 获取上个月的值
  var nextMonth = parseInt(month) + 1;
  nextMonth = nextMonth < 10 ? '0' + nextMonth : nextMonth; // 获取下个月个月的值

  if (parseInt(month) == 1 && type == -1) {//如果是1月份，要上个月 ，则取上一年的12月份
    return (parseInt(year) - 1) + '-12-' + day;
  } else if (parseInt(month) == 12 && type == 1) { // 如果是12月，要下个月，取下一年的1月
    return (parseInt(year) + 1) + '-01-' + day;
  }

  var preSize = new Date(year, parseInt(month) - 1, 0).getDate();//上月总天数
  var nextSize = new Date(year, parseInt(month) + 1, 0).getDate();//下月总天数
  console.log(preSize, nextSize)
  if (preSize < parseInt(day) && type == -1) {// 取上个月，如果上个月总天数小于本月今天，取上个月最后一天    
    return year + '-' + preMonth + '-' + preSize;
  } else if (nextSize < parseInt(day) && type == 1) { // 如果下个月总天数小于本月今天，取下个月最后一天  
    return year + '-' + nextMonth + '-' + nextSize;
  }

  if (type == -1) {
    return year + '-' + preMonth + '-' + day;
  } else if (type == 1) {
    return year + '-' + nextMonth + '-' + day;
  } else if (type == 0) {
    return now;
  }
}

const getStartDate = function () {
  const start = formatDate(
    new Date(new Date(new Date().toLocaleDateString()).getTime())
  )
  return start
}

const getCurentDate = function () {
  const end = formatDate(new Date())
  return end
}

const getCurent = function () {
  const end = formatDate(new Date())
  return end.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "")
}

/**
 * 获取 前天到昨天
 */
const getRangeDay = function (flag) {
  let end = new Date(getStartDate()).getTime() - 3600 * 1000 * 24
  let start = new Date(getStartDate()).getTime() - 3600 * 1000 * 24 * 2
  if (flag) {
    end = new Date(getStartDate()).getTime()
    start = new Date(getStartDate()).getTime() - 3600 * 1000 * 24
  }
  return [start, end]
}

/**
 * 选取时间的限制
 * 正式服务器 设置可选择的日期为2019/10/24 -至今 20200117 没有这个需求了
 * @param {*} time
 */
const checkLimitDate = time => {
  return time.getTime() > Date.now()
}

// 开始时间不能大于等于结束时间
const checkTimeLimit = (start, end, callback) => {
  if (start && start !== '') {
    if (new Date(start).valueOf() >= new Date(end).valueOf()) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}
// 开始时间不能大于结束时间
const checkTimeEqualLimit = (start, end, callback) => {
  if (start && start !== '') {
    if (new Date(start).valueOf() > new Date(end).valueOf()) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

// 排序函数
const compareAscSort = function (property) {
  return function (a, b) {
    var value1 = a[property]
    var value2 = b[property]
    return value1 - value2
  }
}

// gps和经纬度转换
const oneGpsToLngLat = function (lng, lat) {
  return new Promise(function (resolve, reject) {
    AMap.convertFrom([lng, lat], 'gps', function (status, result) {
      if (result.info === 'ok') {
        var resLnglat = result.locations[0]
        resolve([resLnglat.lng, resLnglat.lat])
      }
    })
  })
}
/**
 * 只能查一段时间内的数据
 * young 20200422 默认查询30天内数据
 */
const checkTimeLimitDay = (start, end, day) => {
  day = day || 31 // 默认31天内
  const one = day * 24 * 3600 * 1000
  if (start && start !== '') {
    if (!(new Date(start).valueOf() + one >= new Date(end).getTime())) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}
/**
 * 获取天,周,月数据(当天,一周数据,30天数据)
 * @param {*} dayNum
 */
const getDay = function (dayNum) {
  let returnDay = null
  const date = new Date()
  const day = date.getDay() || 7 // 星期
  const nowDate = date.getDate() // 号数
  const oneDayLong = 24 * 60 * 60 * 1000 // 一天的时间戳长度
  const startTime = new Date(new Date(new Date().toLocaleDateString()).getTime())

  switch (dayNum) {
    case 1:
      returnDay = new Date(startTime)
      break
    case 7:
      const MondayTime = startTime - (day - 1) * oneDayLong
      returnDay = new Date(MondayTime)
      break
    case 30:
      const DateTime = startTime - (nowDate - 1) * oneDayLong
      returnDay = new Date(DateTime)
      break

    default:
      returnDay = new Date(startTime)
      break
  }
  return returnDay
}

/* 获取菜单第一项 */
const menuRecursion = menu => {
  if (menu.subMenuTrees == null || (menu.type === 1 && menu.subMenuTrees[0].type === 2)) {
    return menu
  } else {
    return menuRecursion(menu.subMenuTrees[0])
  }
}

// 表格渲染视图地址未更新使用
const getTablePlaceArr = function (arr, lng, lat, that) {
  arr.map(item => {
    getPlace(item[lng], item[lat]).then(res => {
      that.$set(item, 'location', res)
    })
  })
}

/**
 * 开始时间与结束时间的验证
 * 开始时间不能大于结束时间
 * 不能选择未来时间
 * @param {*string} 开始时间
 * @param {*string} 结束时间
 */
const getTimeIsQualified = function (start, end, flag) {
  if (!start || !end) {
    Message({
      showClose: false,
      message: '请选择起始时间!',
      type: 'error'
    })
    return false
  }
  if (flag) {
    if (new Date(start) > new Date() || new Date(end) > new Date()) {
      Message({
        showClose: false,
        message: '不能选择未来时间!',
        type: 'error'
      })
      return false
    }
  }

  if (new Date(start).valueOf() >= new Date(end).valueOf()) {
    Message({
      showClose: false,
      message: '开始时间不能大于结束时间',
      type: 'error'
    })
    return false
  } else {
    return true
  }
}
export {
  formatDate,
  formatHour,
  formatDay,
  formatMonth,
  getXmonthToday,
  getStartDate,
  getCurentDate,
  getCurent,
  checkTimeLimit,
  checkTimeEqualLimit,
  checkLimitDate,
  checkTimeLimitDay,
  compareAscSort,
  oneGpsToLngLat,
  getDay,
  menuRecursion,
  getTablePlaceArr,
  getTimeIsQualified,
  getRangeDay
}

// 手机号
const checkPhone = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /(^1[3|4|5|7|8|9]\d{9}$)|(^09\d{8}$)/
    if (!regEn.test(value)) {
      callback(new Error('请输入正确的手机号码'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 座机号|手机号
const checkLandLine = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /(^1[3|4|5|7|8|9]\d{9}$)|(^09\d{8}$)|(^0\d{2,3}-\d{7,8}$)/
    if (!regEn.test(value)) {
      callback(new Error('请输入正确的座机号或手机号码'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 密码
// const checkPassword = (rule, value, callback) => {
//   if (value && value !== '') {
//     const regEn = /^[0-9a-zA-Z]{6,18}$/
//     if (!regEn.test(value)) {
//       callback(new Error('请输入6-18位数字或字母'))
//     } else {
//       callback()
//     }
//   } else {
//     callback()
//   }
// }
const checkPassword = (rule, value, callback) => {
  if (value && value !== '') {
    // const regEn = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,16}$/
    const regEn = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+'\-={}:"<>?,.\/]).{8,16}$/
    if (!regEn.test(value)) {
      callback(new Error('请输入8-16位,包含字母、数字、特殊字符的密码'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 用户名
const checkUsername = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[0-9a-zA-Z]{1,11}$/
    if (!regEn.test(value)) {
      callback(new Error('请输入1-11位数字或字母'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 姓名
const checkName = (rule, value, callback) => {
  if (value && value !== '') {
    // 中文或英文（可混合使用）
    // const regEn = /[a-zA-Z\u4E00-\u9FA5]+$/
    // 中文或英文（不可混合使用）
    const regEn = /^([a-zA-Z ]+|[\u4e00-\u9fa5]+)$/
    if (!regEn.test(value)) {
      callback(new Error('请输入中文或英文,不可混合输入'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 节点名称
const checkNodeName = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[\u4e00-\u9fa5-a-zA-Z0-9]+$/
    if (!regEn.test(value)) {
      callback(new Error('请输入中文英文数字或者-'))
    } else {
      callback()
    } 
  } else {
    callback()
  }
}

// url
const checkUrl = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[a-zA-Z]*$/
    // if (!regEn.test(value)) {
    //   callback(new Error('请输入合法的路径'))
    // } else {
    //   callback()
    // }
    callback()
  } else {
    callback()
  }
}

// 资源路径
const checkSourceURL = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^\/[a-zA-Z]+(\/[0-9a-zA-Z]+)*(\.icon|\.img|\.mp4|\.mp3|\.svg)$/
    if (!regEn.test(value)) {
      callback(new Error('请输入合法的资源路径'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 车牌号
const checkPlateNumber = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1})$/
    if (!regEn.test(value)) {
      callback(new Error('请输入合法的车牌号'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 车架号
const checkFrameNumver = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^(?=.*?[A-Z])[0-9A-Z]{17}$/
    if (!regEn.test(value)) {
      callback(new Error('请输入合法的车架号'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 工号
const checkJobNumber = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[0-9a-zA-Z]{1,18}$/
    if (!regEn.test(value)) {
      callback(new Error('工号为不超过18位数字或字母组成'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 从业资格证号
const checkQualificationCertificateNumber = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[0-9A-Z]{18}$/
    if (!regEn.test(value)) {
      callback(new Error('从业资格证号为18位数字或大写字母组成'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 驾驶证号
const checkDriverLicenseNumber = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[0-9A-Z]{1,18}$/
    if (!regEn.test(value)) {
      callback(new Error('驾驶证号为不超过18位数字或大写字母组成'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 身份证号码
const checkIdentificationNumber = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
    if (!regEn.test(value)) {
      callback(new Error('请输入合法的身份证号码'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

/**
 * 输入验证_非负整数
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
const checkPositiveInteger = (rule, value, callback) => {
  if (value && value !== '') {
    const regEnStr = /^(?=[0-9]+$)/
    const regEn = /^[1-9]\d*|0$/
    if (!regEnStr.test(value)) {
      callback(new Error('不能输入特殊字符'))
    } else if (!regEn.test(value)) {
      callback(new Error('请输入整数且不能为负数'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 只能是字母和数字
const checkString = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^(?=[0-9a-zA-Z@_.]+$)/
    if (!regEn.test(value)) {
      callback(new Error('不能输入特殊字符')) // 只能是字母和数字
    } else {
      callback()
    }
  } else {
    callback()
  }
}
// 卡号-数字和字母
const checkCardNo = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^(?=[0-9a-zA-Z]+$)/
    if (!regEn.test(value)) {
      callback(new Error('请输入数字或字母'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

/**
 * IP或域名验证
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
const checkIpAddress = (rule, value, callback) => {
  if (value && value !== '') {
    const patternPort = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5]):([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5])$/

    const pattern = /^(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])$/
    const doname = /^([\w-]+\.)+((com)|(net)|(org)|(gov\.cn)|(info)|(cc)|(com\.cn)|(net\.cn)|(org\.cn)|(name)|(biz)|(tv)|(cn)|(mobi)|(name)|(sh)|(ac)| {3}(io)|(tw)|(com\.tw)|(hk)|(com\.hk)|(ws)|(travel)|(us)|(tm)|(la)|(me\.uk)|(org\.uk)|(ltd\.uk)|(plc\.uk)|(in)|(eu)|(it)|(jp))$/
    const allTest = /^(^((?:(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(?:(\/([1-9]|[1-2]\d|3[0-1]))|(\-((?:(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))))?)|((?:(?:[a-zZ-Z0-9]+)\.){2}((com|org|net)\.)?(com|cn|net|org|biz|info|cc|tv|top|vip)))(\;((?:(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(?:(\/([1-9]|[1-2]\d|3[0-1]))|(\-((?:(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))))?|((?:(?:[a-zZ-Z0-9]+)\.){2}((com|org|net)\.)?(com|cn|net|org|biz|info|cc|tv|top|vip))))*$/
    if (patternPort.test(value) || pattern.test(value) || doname.test(value) || allTest.test(value)) {
      callback()
    } else {
      callback(new Error('不是正确的域名和IP'))
    }
  } else {
    callback()
  }
}

// 经度
const checkLong = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/
    if (!regEn.test(value)) {
      callback(new Error('请输入正确的经度')) // 经度
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 纬度
const checkLat = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/
    if (!regEn.test(value)) {
      callback(new Error('请输入正确的纬度')) // 纬度
    } else {
      callback()
    }
  } else {
    callback()
  }
}
//符合json对象格式的字符串
const checkJSON = (rule,str,callback) => {
  if (str && typeof str == 'string') {
    try {
      var obj=JSON.parse(str);
      if(typeof obj == 'object' && obj ){
        callback()
      }else{
        callback(new Error('请输入符合json对象格式的字符串'));
      }
    }catch(e) {
      callback(new Error('请输入符合json对象格式的字符串'));
    }
  }
}

export {
  checkPhone,
  checkLandLine,
  checkPassword,
  checkUsername,
  checkName,
  checkNodeName,
  checkUrl,
  checkSourceURL,
  checkPlateNumber,
  checkFrameNumver,
  checkJobNumber,
  checkIdentificationNumber,
  checkQualificationCertificateNumber,
  checkDriverLicenseNumber,
  checkPositiveInteger,
  checkString,
  checkIpAddress,
  checkLong,
  checkLat,
  checkJSON,
  checkCardNo
}
/********************************************************************************************** */

/**
 * 处理数据字典数据
 * @param {*} data 
 */
const formatDict = data => {
  if (data.length > 0) {
    let entries = Array.from(data, (item) => {
      return [item.dictCode, item.dictValue]
    })
    return Object.fromEntries(entries)
  } else {
    return {}
  }
}
/** 获取终端的摄像头个数|终端号 
  * @param {*} data 终端号字符串
  * @param {*} flag  摄像头个数
 */
const returnVideoArr = (data, flag) => {
  if (data) {
    let system = sessionStorage.getItem("system")
    let index = data.indexOf(`${store.state.vehicle.terminalType[system]}`)

    if (index === -1) return null
    let params = data.substring(index)
    const videos = params.split(',')[0].split('_')
    return flag ? videos[1] : parseInt(videos[2])
  }
  return null
}

export {
  formatDict,
  returnVideoArr
}
