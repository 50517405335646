/*
create by gw : 2018-10-18  用于实现与gps中心转发服务器的数据交互

*/

import { yh_socket } from "./yh_socket.js";

import { YHTools, opertionType, alarmShield, alarmShieldGps } from "./Tools.js";

import router from "../../router";
import { Message } from "element-ui";
import config from "@/api/static/config.js";
/**
 * purpose   接收Gps服务器数据
 * sRemoteIp  远端ip
 * sUserid     用户id* callObject
 * callBack_DataReturn 数据返回回调   参数(int iPacketID, string sipAddress, VehTempData, thisVehicle, byte[] PacketData, int iVehCount)
 * callBack_InteractionData 交互信息回调  参数(string sVehicleNo, string sTime, string sCmdTxt, string sNote)
 * callBack_ReturnCnnText 连接状态信息回调  参数(string sReturnTex, int CnnStatus)  //CnnStatus 0数据失败 1正在交互数据 2交互数据完成
 */
export class GpsReceiveData {
  /**
   *
   * @param {string} sRemoteIp 连接websocket 地址ip
   * @param {string} sUserid 账号id
   * @param {string} sUserName 账号名称
   * @param {string} sLoginIp 账号登录ip 唯一识别客户端
   * @param {object} callObject
   * @param {Function} callBack_DataReturn data return call back function
   * @param {Function} callBack_InteractionData
   * @param {Function} callBack_ReturnCnnText
   * @param {Function} callBack_returnDownLoadList //历史视频上传列表
   *
   **/
  constructor(
    sRemoteIp,
    sUserid,
    sUserName,
    sLoginIp,
    callObject,
    callBack_DataReturn,
    callBack_InteractionData,
    callBack_ReturnCnnText,
    callBack_returnDownLoadList
  ) {
    this.DataReturnCallBack = callBack_DataReturn;
    this.InteractionDataCallBack = callBack_InteractionData;
    this.ReturnCnnTextCallBack = callBack_ReturnCnnText;
    this.returnDownLoadListCallBack = callBack_returnDownLoadList;
    this.mCallObject = callObject;

    this.TcpSocket = null;
    this.RemoteIp = ""; // 远程ip地址
    this.Userid = ""; // 用户id
    this.LoginIp = ""; // 账号登录ip 唯一识别客户端
    this.UserName = ""; // 当前操作用户名
    this.websocketOnError = 0; //  websocket 错误重连次数

    this.thisLastTime = null; // 接收数据的最后时间
    this.thisLastSendTime = null; // 发送数据的最后时间
    this.MaxLogoutTime = new Date("1970-01-01 00:00:00"); // 登陆时间

    this.bLoggedin = false; // 是否已登录

    this.orderVehcileList = new Map(); // 下发指令的数据缓存   key：数字(orderId)  value：终端号_车牌号_公司名称

    this.orderId = 0; // 下发指令每条数据的id，为了返回数据定位
    this.bExitthrd = false; // 是否退出接收线程
    this.bAutoCnn = false; // 是否可以自动连接到服务器

    this.Create(sRemoteIp, sUserid, sUserName, sLoginIp);
  }

  /* -------------------------------------------------------------------------------回调函数-------------------------------------------------------------- */

  /**
   * 交互信息回调(下发指令,指令返回信息)
   * @param {object} callObject this
   * @param {string} sVehicleNo 车辆号码
   * @param {string} vehGroupName 车辆所属公司
   * @param {string} sTime 操作时间
   * @param {string} sCmdTxt 命令
   * @param {string} orderId 指令id
   * @param {string} terminalNo 终端号
   */

  InteractionDataOut(
    callObject,
    sVehicleNo,
    vehGroupName,
    sTime,
    sCmdTxt,
    orderId,
    terminalNo
  ) {
    if (callObject.InteractionDataCallBack != null) {
      if (callObject.mCallObject != null) {
        callObject.InteractionDataCallBack(
          callObject.mCallObject,
          sVehicleNo,
          vehGroupName,
          sTime,
          sCmdTxt,
          orderId,
          terminalNo
        );
      } else {
        callObject.InteractionDataCallBack(sVehicleNo, sTime, sCmdTxt);
      }
    }
  }

  /**
   * 历史视频上传 列表
   * @param {*} callObject
   * @param {*} data
   * @param {*} terminalNo
   */

  returnDownLoadList(callObject) {
    // console.log('历史视频上传列表：',callObject)
    if (callObject.returnDownLoadListCallBack != null) {
      if (callObject.mCallObject != null) {
        callObject.returnDownLoadListCallBack(callObject.mCallObject);
      } else {
        callObject.returnDownLoadListCallBack();
      }
    }
  }

  // 数据返回
  ReturnCmdData(callObject, dataType, btPacketData) {
    // console.log('数据返回',callObject, dataType, btPacketData)
    if (callObject.DataReturnCallBack != null) {
      if (callObject.mCallObject != null) {
        callObject.DataReturnCallBack(
          callObject.mCallObject,
          dataType,
          btPacketData
        );
      } else {
        callObject.DataReturnCallBack(dataType, btPacketData);
      }
    }
  }

  // 连接状态信息nCnnStatus 0数据失败 1正在交互数据 2交互数据完成
  thisReturnCnnText(callObject, sReturnText, nCnnStatus) {
    if (callObject.ReturnCnnTextCallBack != null) {
      if (callObject.mCallObject != null) {
        callObject.ReturnCnnTextCallBack(
          callObject.mCallObject,
          sReturnText,
          nCnnStatus
        );
      } else {
        callObject.ReturnCnnTextCallBack(sReturnText, nCnnStatus);
      }
    }
  }
  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /* -------------------------------------------------------------------------------操作指令相关函数-------------------------------------------------------------- */

  /// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * websocket返回的数据解析
   * 位置数据/报警数据/指令数据做区分
   * @param {Object} callObject 对象本身
   * @param {Object} returnData 返回数据
   */
  AddDataPacket(callObject, returnData) {
    // console.log('websocket返回的数据解析',returnData)
    // 这里接受到了数据,callObject
    const responceData = JSON.parse(returnData);
    try {
      switch (responceData.type) {
        case "0xEE":
          // 登陆失败！
          callObject.thisReturnCnnText(callObject, "登陆失败！", 0);
          callObject.bExitthrd = true;
          callObject.TcpSocket.closeSocket();
          break;
        case "0xF5":
          // 用户抢占视频资源！
          callObject.ReturnCmdData(callObject, responceData.type, returnData);
          break;
        case "0xE2":
          // 报警信息
          callObject.ReturnCmdData(callObject, responceData.type, returnData);
          break;
        case "0xF4":
          // 长时间离线通知
          callObject.ReturnCmdData(callObject, responceData.type, returnData);
          break;
        case "0xE3": // 车辆拍照返回数据
          callObject.ReturnCmdData(callObject, responceData.type, returnData);
          break;
        case "0xE4": // 车辆在线,下线提醒
          callObject.ReturnCmdData(callObject, responceData.type, returnData);
          break;
        case "0xE5": // 获取聊天消息数据(获取)提醒用户系统已更新
          callObject.ReturnCmdData(callObject, responceData.type, returnData);
          break;
        case "0xE6": // 发送聊天消息数据
          break;
        case "0xE7": // 下发指令|获取车辆视频相关指令
          callObject.ReturnCmdData(callObject, responceData.type, returnData);
          break;

        case "0xE8": // 需要登出
          callObject.ReturnCmdData(callObject, responceData.type, returnData);
          break;
        case "0xEC": // 获取上级平台的消息数据(获取)20200109-查岗
          callObject.ReturnCmdData(callObject, responceData.type, returnData);
          break;
        case "0xF6": // 福建报警督办
          callObject.ReturnCmdData(callObject, responceData.type, returnData);
          break;
        case "0xF3": // 福建查岗
          callObject.ReturnCmdData(callObject, responceData.type, returnData);
          break;
        case "0xEB": // 收到报警督办信息20200109
          callObject.ReturnCmdData(callObject, responceData.type, returnData);

          break;

        default:
          break;
      }
    } catch (e) {
      // 数据解析  异常
      console.error("GpsReceiveData  AddDataPacket  error: " + e.message);
    }
  }

  /**
   * Socket连接成功回调 2020-06-19
   * @param {object} callObject  callObject callObject其实就是此类本身，这里再传是因为js回调机制的原因，回调后this就成了window了
   * @param {object} evt evt 返回的数据
   */

  Socket_Open(callObject, evt) {
    callObject.thisReturnCnnText(
      callObject,
      "登陆成功！正准备从服务器更新数据。",
      2
    );
    callObject.bLoggedin = true;
    callObject.websocketOnError = 0;
  }

  /**
   * Socket接收到消息
   * callObject其实就是此类本身，这里再传是因为js回调机制的原因，回调后this就成了window了
   * @param {object} callObject 此类本身
   * @param {object} returnData 返回数据
   */

  Socket_OnMessage(callObject, returnData) {
    // 这里接到了数据,
    callObject.thisLastTime = new Date();
    callObject.websocketOnError = 0;
    callObject.AddDataPacket(callObject, returnData);
  }

  /**
   * Socket连接关闭
   * 在非正常断开情况下去重新连接
   * @param {*} callObject
   * @param {*} evt
   */
  Socket_OnClose(callObject, evt) {
    /**尝试重连三次，失败就让用户重新登录 */
    if (callObject.websocketOnError < 3) {
      // console.log('尝试重连',callObject.websocketOnError)
      if (evt.code === 1006 || evt.code === 1001 || !evt.wasClean) {
        setTimeout(() => {
          callObject.StartConnectServer(callObject);
          callObject.websocketOnError = callObject.websocketOnError + 1;
        }, 1000);
      } else {
        callObject.thisReturnCnnText(callObject, "服务器连接出现问题。", 888);
      }
    } else {
      callObject.thisReturnCnnText(callObject, "服务器连接出现问题。", 888);
      /**
       * 跳到登录页---websocket重连超过三次次
       */
      Message.error({
        message: "重连失败，请重新登录~"
      });

      if (config.apiBaseUrl != "api_target/") {
        setTimeout(() => {
          router.push({ path: "/login" });
        }, 3000);
      }
    }
  }

  /**
   * Socket通信错误
   * @param {*} callObject
   * @param {*} evt
   */
  Socket_OnError(callObject, evt) {
    callObject.thisReturnCnnText(callObject, "服务器连接关闭。", 999);
  }

  // 发送数据到服务器
  Socket_SendData(byteArray) {
    if (this.bExitthrd) {
      // 退出发送了
      return;
    }
    if (!this.TcpSocket.sendData(byteArray)) {
      console.log("网络有问题");
    }
  }

  // Socket心跳包
  Socket_SendHeartBeat(callObject) {
    // if (callObject.TcpSocket == null || !callObject.TcpSocket.Connected) {
    //   // 退出发送了
    //   return
    // }
    // callObject.SendHeartBeat(callObject)
    // setTimeout(function () {
    //   callObject.Socket_SendHeartBeat(callObject)
    // }, 30 * 1000)
  }

  /** 发送心跳包 */
  SendHeartBeat(callObject) {
    try {
      // callObject.TcpSocket.sendData(null, '0xEF')
    } catch (error) {
      console.error("SendHeartBeat : ");
    }
  }

  // 开启连接到中心服务器
  StartConnectServer(callObject) {
    try {
      if (!callObject.TcpSocket || !callObject.TcpSocket.Connected) {
        // 重新建立socket连接
        callObject.TcpSocket = new yh_socket(
          callObject.RemoteIp,
          callObject.Userid,
          callObject.LoginIp,
          callObject.Socket_Open,
          callObject.Socket_OnMessage,
          callObject.Socket_OnClose,
          callObject.Socket_OnError,
          callObject
        );
        setTimeout(function() {
          callObject.Socket_SendHeartBeat(callObject);
        }, 1 * 1000);
      }
    } catch (e) {
      // 如果不是需要退出网络连接，那么在出现异常的时候进行重新连接操作
      console.error("GpsReceiveData  StartConnectServer  error: " + e.message);
      console.error("tcpsocket : " + callObject.TcpSocket);
    }
  }

  // 关闭连接到中心服务器的线程
  CloseConnectServer() {
    if (this.TcpSocket != null) {
      this.TcpSocket.closeSocket(); // young 20190817修改
      this.TcpSocket = null;
    }
    // this.bIsPassLogin = false;
  }

  // socket连接函数
  SocketConnect(bCnnStart, bAutoCnn) {
    try {
      if (bCnnStart) {
        // 连接到服务器
        this.bAutoCnn = bAutoCnn;
        this.bExitthrd = false;
        this.StartConnectServer(this);
      } else {
        // 断开服务器
        this.bAutoCnn = false;
        this.bExitthrd = true;
        this.CloseConnectServer();
      }
    } catch (e) {
      // 提示网络出现错误
      console.error("GpsReceiveData  SocketConnect  error: " + e.message);
    }
  }

  // 创建函数
  Create(sRemoteIp, sUserid, sUserName, sLoginIp) {
    this.RemoteIp = sRemoteIp;
    this.Userid = String(sUserid);
    this.UserName = String(sUserName);
    this.LoginIp = sLoginIp;

    this.thisLastTime = new Date(); // 接收数据的最后时间
    this.thisLastSendTime = new Date(); // 发送数据的最后时间
    this.MaxLogoutTime = new Date(); // 登陆时间
  }

  /**
   * 组装好数据,通过websocket发送
   * 数据格式: //具体参考石墨文档
   * @param {object} params 数据
   * @param {string} sCmdTxt 命令
   * @param {string} sNote 内容
   */
  SendCmdToCenter(params, sCmdTxt) {
    const data = JSON.parse(params);
    try {
      if (this.TcpSocket.Connected) {
        this.orderId = this.orderId + 1;
        this.thisLastSendTime = new Date();
        data.webFlowId = this.orderId;
        /** 设置终端参数 */
        if (data.command === 33027) {
          for (let i = 0; i < data.params.length / 2; i++) {
            const element = data.params[i * 2];

            switch (element) {
              // case 0x0055: // 设置最高速度
              //   data.params[i * 2 + 1] = data.params[i * 2 + 1]
              //   break
              case 0x005b: // 预警提前量-超速
                data.params[i * 2 + 1] = data.params[i * 2 + 1] * 10;
                break;
              case 0x0057: // 设置连续驾驶时间门限
                data.params[i * 2 + 1] = data.params[i * 2 + 1] * 60;
                break;
              case 0x0058: // 设置当天累计驾驶时间门限
                data.params[i * 2 + 1] = data.params[i * 2 + 1] * 60;
                break;
              case 0x0059: // 设置最小休息时间
                data.params[i * 2 + 1] = data.params[i * 2 + 1] * 60;
                break;
              case 0x005c: // 预警提前量-疲劳
                data.params[i * 2 + 1] = data.params[i * 2 + 1] * 60;
                break;
              case 0x005a: // 设置当天累计驾驶时间门限
                data.params[i * 2 + 1] = data.params[i * 2 + 1] * 60;
                break;
              default:
                break;
            }
          }
        }
        this.TcpSocket.sendData(JSON.stringify(data), "0xE7");
      } else {
        this.thisReturnCnnText(this, "服务器连接出现问题。", 888);
      }
      /** 历史视频上传  提示用户 "导出文件后台生成中，完成后请到下载中心下载文件。" */
      if (data.command === 0x9206) {
        this.returnDownLoadList(this);
        return;
      }
      /**
       * 视频部分指令不显示到指令信息中
       */
      if (
        data.command === 0x9101 ||
        data.command === 0x9102 ||
        data.command === 0x9205 ||
        data.command === 0x9201 ||
        data.command === 0x9202
      ) {
        return;
      }
      //附件指令

      if (data.command === 37384) {
        return;
      }
      const orderParams = JSON.parse(params);
      let orderText = sCmdTxt;
      // console.log('****',orderParams)
      orderParams.terminalDetail.forEach((res, i) => {
        let item = orderParams.terminalPhoneNos[i];
        const value = res.split("#");
        const sVehicleNo = value[0] ? value[0] : "暂无车辆数据";
        const vehGroupName = value[1] ? value[1] : "暂无公司数据";
        const time = YHTools.DateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
        /**查询车辆行驶记录 */
        if (orderParams.command === 0x8700) {
          let text = "";
          const functionsOptions = [
            { value: 0, label: "采集记录仪执行标准版本" },
            { value: 1, label: "采集当前驾驶人信息" },
            { value: 2, label: "采集记录仪实时时间" },
            { value: 3, label: "采集累计行驶里程" },
            { value: 4, label: "采集记录仪脉冲系数" },
            { value: 5, label: "采集车辆信息" },
            { value: 6, label: "采集记录仪状态信号配置信息" },
            { value: 7, label: "采集记录仪唯一性编号" },
            { value: 8, label: "采集指定的行驶速度记录" },
            { value: 9, label: "采集指定的位置信息记录" },
            { value: 16, label: "采集指定的事故疑点记录" },
            { value: 17, label: "采集指定的超时驾驶记录" },
            { value: 18, label: "采集指定的驾驶人身份记录" },
            { value: 19, label: "采集指定的外部供电记录" },
            { value: 20, label: "采集指定的参数修改记录" },
            { value: 21, label: "采集指定的速度状态日志" }
          ];
          const cmdText = opertionType.get(0x8700);
          sCmdTxt =
            orderParams.params.length > 1
              ? `${cmdText}_功能：${
                  functionsOptions.find(
                    item => orderParams.params[0] == item.value
                  ).label
                },开始时间：${YHTools.DateFormat(
                  new Date(orderParams.params[1] * 1000),
                  "yyyy-MM-dd HH:mm:ss"
                )},结束时间：${YHTools.DateFormat(
                  new Date(orderParams.params[2] * 1000),
                  "yyyy-MM-dd HH:mm:ss"
                )}`
              : `${cmdText}_功能：${
                  functionsOptions.find(
                    item => orderParams.params[0] == item.value
                  ).label
                }`;
        }
        /** 文本信息下发 */
        if (orderParams.command === 0x8300) {
          let text = "";
          const cmdText = opertionType.get(0x8300);
          text += `${cmdText.split("-")[1]}：${orderParams.params[0]},`;
          text += `${cmdText.split("-")[2]}：${orderParams.params[1]},`;
          sCmdTxt = `${orderText}_${text}`;
        }
        /** 设置终端参数 */
        if (orderParams.command === 33027) {
          // console.log('查看夜间禁行是否有返回值',orderParams)
          let text = "";
          for (let i = 0; i < orderParams.params.length / 2; i++) {
            const element = orderParams.params[i * 2];
            const cmdText = opertionType.get(element);
            if (element === 62323) {
              //限高
              text = `${cmdText.split("-")[1]},车身高度：${
                orderParams.params[i * 2 + 1][0]
              }毫米,车身宽度：${orderParams.params[i * 2 + 1][1]}毫米,`;
            } else if (element === 62322) {
              //载重
              text = `${cmdText.split("-")[1]},车辆限定总质量：${
                orderParams.params[i * 2 + 1][0]
              }*10Kg,发动机最大扭矩：${
                orderParams.params[i * 2 + 1][1]
              }牛.米,车速1 CAN报文ID：${
                orderParams.params[i * 2 + 1][2]
              },车速2 CAN报文ID：${
                orderParams.params[i * 2 + 1][3]
              },监测设备安装方向：${
                orderParams.params[i * 2 + 1][4]
              },载重计算周期：${
                orderParams.params[i * 2 + 1][5]
              }*10毫秒,设置计算滤波系数：${
                orderParams.params[i * 2 + 1][6]
              },加速度初始值：${orderParams.params[i * 2 + 1][7]},`;
            } else if (element === 117) {
              //音视频参数
              const encodeModeList = [
                { dictValue: "CBR(固定码率)", dictCode: 0 },
                { dictValue: "VBR(可变码率)", dictCode: 1 },
                { dictValue: "ABR(平均码率)", dictCode: 2 }
              ];
              let modelText = "";
              let rListText = "";
              let smodelText = "";
              let sListText = "";
              let op = "";
              encodeModeList.map(item => {
                if (orderParams.params[i * 2 + 1][0] === item.dictCode) {
                  modelText = item.dictValue;
                }
                if (orderParams.params[i * 2 + 1][5] === item.dictCode) {
                  smodelText = item.dictValue;
                }
              });
              const resolutionRatioList = [
                { dictValue: "QCIF", dictCode: 0 },
                { dictValue: "CIF", dictCode: 1 },
                { dictValue: "WCIF", dictCode: 2 },
                { dictValue: "D1", dictCode: 3 },
                { dictValue: "WD1", dictCode: 4 },
                { dictValue: "720P", dictCode: 5 },
                { dictValue: "1080P", dictCode: 6 }
              ];
              resolutionRatioList.map(item => {
                if (orderParams.params[i * 2 + 1][1] === item.dictCode) {
                  rListText = item.dictValue;
                }
                if (orderParams.params[i * 2 + 1][6] === item.dictCode) {
                  sListText = item.dictValue;
                }
              });
              const openList = [
                { dictValue: "是", dictCode: 1 },
                { dictValue: "否", dictCode: 0 }
              ];
              openList.map(item => {
                if (orderParams.params[i * 2 + 1][11] === item.dictCode) {
                  op = item.dictValue;
                }
              });
              //二进制转换
              let bfArr = Number(orderParams.params[i * 2 + 1][10]);
              let bit2 = bfArr
                .toString(2)
                .split("")
                .reverse();
              let l1 = bit2.length;
              bit2.length = 16;
              let bit16 = bit2.fill("0", l1, 16);
              let nameArr = [];
              let bitText = "";
              let arrList = [
                { value: 0, label: "日期和时间" },
                { value: 1, label: "车牌号码" },
                { value: 2, label: "逻辑通道号" },
                { value: 3, label: "经纬度" },
                { value: 4, label: "行驶记录速度" },
                { value: 5, label: "卫星定位速度" },
                { value: 6, label: "连续驾驶时间" }
              ];
              bit16.forEach((item, ind) => {
                if (item == "1") {
                  let current =
                    arrList.find(temp => temp.value === Number(ind)) || false;
                  current && nameArr.push(current);
                }
              });
              nameArr.map(item => {
                bitText += `${item.label}、`;
              });
              text = `${
                cmdText.split("-")[1]
              },实时流编码模式：${modelText},实时流分辨率：${rListText},实时流关键帧间隔：${
                orderParams.params[i * 2 + 1][2]
              },实时流目标帧率：${
                orderParams.params[i * 2 + 1][3]
              },实时流目标码率：${
                orderParams.params[i * 2 + 1][4]
              }kbps,存储流编码模式：${smodelText},存储流分辨率：${sListText},存储流关键帧间隔：${
                orderParams.params[i * 2 + 1][7]
              },存储流目标帧率：${
                orderParams.params[i * 2 + 1][8]
              },存储流目标码率：${
                orderParams.params[i * 2 + 1][9]
              }kbps,是否启用音视频输出：${op},OSD字幕叠加设置：${bitText},`;
            } else if (element === 61441) {
              //限速
              text += `${cmdText.split("-")[1]},夜间限速开关：${
                orderParams.params[i * 2 + 1][0] === 1 ? "打开" : "关闭"
              },夜间限速比例(%)：${
                orderParams.params[i * 2 + 1][1]
              },限速开始时间：${
                orderParams.params[i * 2 + 1][2]
              },限速结束时间：${orderParams.params[i * 2 + 1][3]},`;
            } else if (element === 62308) {
              // console.log('应答的参数格式',orderParams)
              let adasRequest = orderParams.params[i * 2 + 1];
              let innerText = "";
              const takeList = [
                { dictValue: "不开启", dictCode: 0 },
                { dictValue: "定时拍照", dictCode: 1 },
                { dictValue: "定距拍照", dictCode: 2 },
                { dictValue: "保留", dictCode: 3 }
              ];
              const ratioList = [
                { dictValue: "352*288", dictCode: 1 },
                { dictValue: "704*288", dictCode: 2 },
                { dictValue: "704*576", dictCode: 3 },
                { dictValue: "640*480", dictCode: 4 },
                { dictValue: "1280*720", dictCode: 5 },
                { dictValue: "1920*1080", dictCode: 6 }
              ];
              const resolutionRatioList = [
                { dictValue: "CIF", dictCode: 1 },
                { dictValue: "HD1", dictCode: 2 },
                { dictValue: "D1", dictCode: 3 },
                { dictValue: "WD1", dictCode: 4 },
                { dictValue: "VGA", dictCode: 5 },
                { dictValue: "720P", dictCode: 6 },
                { dictValue: "1080P", dictCode: 7 }
              ];
              adasRequest.map((itm, idx) => {
                //排除第9、10、11、49项，单独解析第9项//后期优化
                if (idx === 9) {
                  let bfArr = Number(itm.value);
                  let bit2 = bfArr
                    .toString(2)
                    .split("")
                    .reverse();
                  let l1 = bit2.length;
                  bit2.length = 32;
                  let bit32 = bit2.fill("0", l1, 32);
                  let tempArr = [
                    {
                      name: "障碍物一级报警",
                      value: bit32[0] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "障碍物二级报警",
                      value: bit32[1] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "频繁变道一级报警",
                      value: bit32[2] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "频繁变道二级报警",
                      value: bit32[3] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "车道偏离一级报警",
                      value: bit32[4] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "车道偏离二级报警",
                      value: bit32[5] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "前向碰撞一级报警",
                      value: bit32[6] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "前向碰撞二级报警",
                      value: bit32[7] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "行人碰撞一级报警",
                      value: bit32[8] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "行人碰撞二级报警",
                      value: bit32[9] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "车距过近一级报警",
                      value: bit32[10] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "车距过近二级报警",
                      value: bit32[11] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "道路标识超速报警",
                      value: bit32[16] === "1" ? "打开" : "关闭"
                    }
                  ];
                  tempArr.map(i => {
                    innerText += `${i.name}：${i.value},`;
                  });
                  //兼容
                  adasRequest.length > 46 &&
                    (innerText += `碰撞侧翻报警：${
                      bit32[17] ? "打开" : "关闭"
                    },`);
                } else if (idx === 2) {
                  let t = takeList.find(item => item.dictCode === itm.value);
                  innerText += `${itm.name}：${t.dictValue},`;
                } else if (idx === 7) {
                  let r = ratioList.find(item => item.dictCode === itm.value);
                  innerText += `${itm.name}：${r.dictValue},`;
                } else if (idx === 8) {
                  let re = resolutionRatioList.find(
                    item => item.dictCode === itm.value
                  );
                  innerText += `${itm.name}：${re.dictValue},`;
                } else if (
                  idx !== 10 &&
                  idx != 11 &&
                  idx != adasRequest.length - 1
                ) {
                  innerText += `${itm.name}：${itm.value},`;
                }
              });
              text += `${cmdText.split("-")[1]},${innerText}`;
            } else if (element === 62309) {
              let adasRequest = orderParams.params[i * 2 + 1];
              let innerText = "";
              const driverList = [
                { dictValue: "不开启", dictCode: 0 },
                { dictValue: "定时触发", dictCode: 1 },
                { dictValue: "定距触发", dictCode: 2 },
                { dictValue: "插卡开始行驶触发", dictCode: 3 },
                { dictValue: "保留", dictCode: 4 }
              ];
              const takeList = [
                { dictValue: "不开启", dictCode: 0 },
                { dictValue: "定时拍照", dictCode: 1 },
                { dictValue: "定距拍照", dictCode: 2 },
                { dictValue: "保留", dictCode: 3 }
              ];
              const ratioList = [
                { dictValue: "352*288", dictCode: 1 },
                { dictValue: "704*288", dictCode: 2 },
                { dictValue: "704*576", dictCode: 3 },
                { dictValue: "640*480", dictCode: 4 },
                { dictValue: "1280*720", dictCode: 5 },
                { dictValue: "1920*1080", dictCode: 6 }
              ];
              const resolutionRatioList = [
                { dictValue: "CIF", dictCode: 1 },
                { dictValue: "HD1", dictCode: 2 },
                { dictValue: "D1", dictCode: 3 },
                { dictValue: "WD1", dictCode: 4 },
                { dictValue: "VGA", dictCode: 5 },
                { dictValue: "720P", dictCode: 6 },
                { dictValue: "1080P", dictCode: 7 }
              ];
              adasRequest.map((itm, idx) => {
                //排除第9、10、13、46项，单独解析第9项//后期优化
                if (idx === 9) {
                  let bfArr = Number(itm.value);
                  let bit2 = bfArr
                    .toString(2)
                    .split("")
                    .reverse();
                  let l1 = bit2.length;
                  bit2.length = 32;
                  let bit32 = bit2.fill("0", l1, 32);
                  let tempArr = [
                    {
                      name: "疲劳驾驶一级报警",
                      value: bit32[0] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "疲劳驾驶二级报警",
                      value: bit32[1] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "接打电话一级报警",
                      value: bit32[2] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "接打电话二级报警",
                      value: bit32[3] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "抽烟一级报警",
                      value: bit32[4] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "抽烟二级报警",
                      value: bit32[5] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "长时间不目视前方一级报警",
                      value: bit32[6] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "长时间不目视前方二级报警",
                      value: bit32[7] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "未检测到驾驶员二级报警",
                      value: bit32[9] === "1" ? "打开" : "关闭"
                    }
                  ];
                  tempArr.map(i => {
                    innerText += `${i.name}：${i.value},`;
                  });
                  //兼容
                  adasRequest.length > 37 &&
                    (innerText += `夜间禁行报警：${
                      bit32[10] === "1" ? "打开" : "关闭"
                    },超速驾驶报警：${bit32[11] === "1" ? "打开" : "关闭"},`);
                } else if (idx === 2) {
                  let t = takeList.find(item => item.dictCode === itm.value);
                  innerText += `${itm.name}：${t.dictValue},`;
                } else if (idx === 7) {
                  let r = ratioList.find(item => item.dictCode === itm.value);
                  innerText += `${itm.name}：${r.dictValue},`;
                } else if (idx === 8) {
                  let re = resolutionRatioList.find(
                    item => item.dictCode === itm.value
                  );
                  innerText += `${itm.name}：${re.dictValue},`;
                } else if (idx === 34) {
                  let d = driverList.find(item => item.dictCode === itm.value);
                  innerText += `${itm.name}：${d.dictValue},`;
                } else if (
                  idx !== 10 &&
                  idx !== 13 &&
                  idx !== adasRequest.length - 1
                ) {
                  innerText += `${itm.name}：${itm.value},`;
                }
              });
              text += `${cmdText.split("-")[1]},${innerText}`;
            } else if (element === 62310) {
              // console.log('应答的参数格式',orderParams)
              let adasRequest = orderParams.params[i * 2 + 1];
              let innerText = "";
              const unitList = [
                { dictValue: "kg/cm2", dictCode: 0 },
                { dictValue: "bar", dictCode: 1 },
                { dictValue: "Kpa", dictCode: 2 },
                { dictValue: "PSI", dictCode: 3 }
              ];
              adasRequest.map((itm, idn) => {
                if (idn === 1) {
                  let u = unitList.find(item => item.dictCode === itm.value);
                  innerText += `${itm.name}：${u.dictValue},`;
                } else if (idn !== adasRequest.length - 1) {
                  innerText += `${itm.name}：${itm.value},`;
                }
              });
              text += `${cmdText.split("-")[1]},${innerText}`;
            } else if (element === 62311) {
              let adasRequest = orderParams.params[i * 2 + 1];
              let innerText = "";
              adasRequest.map(itm => {
                innerText += `${itm.name}：${itm.value},`;
              });
              text += `${cmdText.split("-")[1]},${innerText}`;
            } else if (element === 62320) {
              //循环数组对象--第0项是报警使能单独解析
              let adasRequest = orderParams.params[i * 2 + 1];
              let innerText = "";
              adasRequest.map((itm, ind) => {
                if (ind === 0) {
                  let bfArr = Number(itm.value);
                  let bit2 = bfArr
                    .toString(2)
                    .split("")
                    .reverse();
                  let l1 = bit2.length;
                  bit2.length = 32;
                  let bit32 = bit2.fill("0", l1, 32);
                  let tempArr = [
                    {
                      name: "急加速报警",
                      value: bit32[0] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "急减速报警",
                      value: bit32[1] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "急转弯报警",
                      value: bit32[2] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "怠速报警",
                      value: bit32[3] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "异常熄火报警",
                      value: bit32[4] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "空挡滑行报警",
                      value: bit32[5] === "1" ? "打开" : "关闭"
                    },
                    {
                      name: "发动机超转报警",
                      value: bit32[6] === "1" ? "打开" : "关闭"
                    }
                  ];
                  tempArr.map(i => {
                    innerText += `${i.name}：${i.value},`;
                  });
                } else if (ind !== adasRequest.length - 1) {
                  innerText += `${itm.name}：${itm.value},`;
                }
              });
              text += `${cmdText.split("-")[1]},${innerText}`;
            } else if (element === 149) {
              text = `${cmdText.split("-")[1]}：${
                orderParams.params[i * 2 + 1] === 49
                  ? "计价器解锁"
                  : "计较器加锁"
              }`;
            } else {
              text += `${cmdText.split("-")[1]}：${
                orderParams.params[i * 2 + 1]
              },`;
            }
          }
          sCmdTxt = `${orderText}_${text}`;
        }
        this.InteractionDataOut(
          this,
          sVehicleNo,
          vehGroupName,
          time,
          sCmdTxt,
          this.orderId,
          item
        );
      });
    } catch (e) {
      console.error("GpsReceiveData  SendCmdToCenter  error: " + e.message);
    }
  }

  /**
   * 下发指令 (与上面功能冲突|不需要显示在指令信息中) young 20.09.11
   * 应答上级平台消息 20200106新需求
   * @param {*} params
   */
  SendData(params, type) {
    // console.log("查岗组装的数据", params, type);
    try {
      if (this.TcpSocket.Connected) {
        const orderParams = JSON.parse(params);
        this.orderId = this.orderId + 1;
        orderParams.webFlowId = this.orderId;
        this.TcpSocket.sendData(JSON.stringify(orderParams), type);
        // 设置IC卡参数
        if (orderParams.command === 0x8300) {
          const sText = "设置IC卡参数";
          orderParams.terminalDetail.forEach((res, i) => {
            let item = orderParams.terminalPhoneNos[i];
            const value = res.split("#");
            const sVehicleNo = value[0] ? value[0] : "暂无车辆数据";
            const vehGroupName = value[1] ? value[1] : "暂无公司数据";
            const time = YHTools.DateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
            this.InteractionDataOut(
              this,
              sVehicleNo,
              vehGroupName,
              time,
              `${sText}_写卡参数*`,
              this.orderId,
              item
            );
          });
        }
        //屏蔽报警
        if (orderParams.command === 33027) {
          // console.log('now',orderParams)
          let text = "";
          let sText =
            orderParams.params[0] === 0x0050 ? "屏蔽报警" : "关闭报警声音";
          orderParams.terminalDetail.forEach((res, i) => {
            let item = orderParams.terminalPhoneNos[i];
            const value = res.split("#");
            const sVehicleNo = value[0] ? value[0] : "暂无车辆数据";
            const vehGroupName = value[1] ? value[1] : "暂无公司数据";
            const time = YHTools.DateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
            //区分GPS和出租车
            if (orderParams.terminalType !== "1") {
              let lockValue = parseInt(orderParams.params[1])
                .toString(2)
                .padStart(32, 0);
              lockValue
                .split("")
                .reverse()
                .map((item, i) => {
                  text += item === "1" ? `${alarmShieldGps[i]}：开启,` : "";
                });
            } else {
              let lockValue = parseInt(orderParams.params[1])
                .toString(2)
                .padStart(29, 0);
              lockValue
                .split("")
                .reverse()
                .map((item, i) => {
                  text += item === "1" ? `${alarmShield[i]}：关闭,` : "";
                });
            }

            this.InteractionDataOut(
              this,
              sVehicleNo,
              vehGroupName,
              time,
              `${sText}_${text}`,
              this.orderId,
              item
            );
          });
        }
        //查岗
        // if (orderParams.type === 0x1301) {
        //   console.log("查岗指令下发成功");
        // }
      } else {
        this.thisReturnCnnText(this, "服务器连接出现问题。", 888);
      }
    } catch (e) {
      // TODO handle the exception
      console.error("GpsReceiveData  SendData  error: " + e.message);
    }
  }
  /**
   * 下发调度屏升级指令 (与上面功能冲突|不需要显示在指令信息中) young 21.03.16
   * @param {*} params
   */
  sendUpgradeData(params, type) {
    try {
      if (this.TcpSocket.Connected) {
        const orderParams = JSON.parse(params);
        this.orderId = this.orderId + 1;
        orderParams.webFlowId = this.orderId;
        this.TcpSocket.sendUpgradeData(JSON.stringify(orderParams), type);
        console.log("------sendUpgradeData", params);
      } else {
        this.thisReturnCnnText(this, "服务器连接出现问题。", 888);
      }
    } catch (e) {
      // TODO handle the exception
      console.error("GpsReceiveData  SendData  error: " + e.message);
    }
  }
}
