/**
 * create by gw : 2018-09-17
 * 用于创建于后台通信的网络
 * 默认是通过二进制进行数据传输的，   注意websocket是可以用文本等其他模式进行传输的
 */

/**
 * purpose : 创建网络对象
 * str_ip    ip地址
 * nport      端口号
 * callObject  回调的对象   -- 不赋值就是window对象
 * openCB    连接成功回调     (evt)
 * messageCB 接收消息回调     (byte[] bta)  参数为：byte数组
 * closeCB   关闭连接回调(evt)
 * errorCB  通信错误回调  (evt)
 */
import TripleDES from '@/api/lib/crypto.js'
export class yh_socket {
  constructor(strIp, userid, loginIp, openCB, messageCB, closeCB, errorCB, callObject = null) {
    this._strIp = strIp
    this._userid = userid
    this._loginIp = loginIp
    this._callObject = callObject
    this._openCB = openCB
    this._messageCB = messageCB
    this._closeCB = closeCB
    this._errorCB = errorCB
    this.__ws = null

    this.create(this._openCB, this._messageCB, this._closeCB, this._errorCB, this._callObject)
  }

  /**
   * 发送数据
   * @param {Object}} btData 下发指令json数据
   * @param {*} type  下发消息类型
   * 公交需要下发的也是adas指令为808 参数2要改为0
   */
  sendData (btData, type) {
    if (this._ws != null || this._ws.readyState === WebSocket.OPEN) {
      try {
        let sendData = JSON.parse(btData)
        let terminalType = sendData.terminalType
        if (sendData.terminalType === '2') {
          terminalType = "0"
        }
        let postValue = {
          type: type,
          terminalType: terminalType
        }
        delete sendData.terminalType
        delete sendData.terminalDetail
        if (type !== '0xEF') {
          const data = {
            userId: this._userid,
            token: sessionStorage.getItem('token') || localStorage.getItem('token')
          }
          postValue.data = {
            ...sendData,
            ...data
          }
        }
        this._ws.send(TripleDES.encode(JSON.stringify(postValue)))

        return true
      } catch (e) {
        return false
      }
    } else {
      return false
    }
  }

  /**
 * 发送公交升级数据
 * @param {Object}} btData 下发指令json数据
 * @param {*} type  下发消息类型
 */
  sendUpgradeData (btData, type) {
    if (this._ws != null || this._ws.readyState === WebSocket.OPEN) {
      try {
        let sendData = JSON.parse(btData)
        let terminalType = sendData.terminalType

        let postValue = {
          type: type,
          terminalType: terminalType
        }
        delete sendData.terminalType
        delete sendData.terminalDetail
        if (type !== '0xEF') {
          const data = {
            userId: this._userid,
            token: sessionStorage.getItem('token') || localStorage.getItem('token')
          }
          postValue.data = {
            ...sendData,
            ...data
          }
        }

        this._ws.send(TripleDES.encode(JSON.stringify(postValue)))

        return true
      } catch (e) {
        return false
      }
    } else {
      return false
    }
  }

  // 关闭连接
  closeSocket () {
    if (this._ws != null && (this._ws.readyState === WebSocket.OPEN || this._ws.readyState === WebSocket.CONNECTING)) {
      this._ws.close()
    }
  }

  // websocket连接状态
  get readyState () {
    if (this._ws != null) {
      return this._ws.readyState
    } else {
      return WebSocket.CLOSED
    }
  }

  get Connected () {
    return (this.readyState === WebSocket.OPEN)
  }

  create (openCB, messageCB, closeCB, errorCB, callObject = null) {
    // 创建websocket，以及赋值各种回调
    if (!!window.WebSocket && window.WebSocket.prototype.send) {
      const strAddress = `${this._strIp}${sessionStorage.getItem('token') || localStorage.getItem('token')}`
      this._ws = new WebSocket(strAddress)

      this._ws.onopen = function (evt) {

        if (openCB != null) {
          if (callObject != null) {
            openCB(callObject)
          } else {
            openCB()
          }
        }
      }
      // 接收消息
      this._ws.onmessage = function (evt) {
        if (messageCB != null) {
          const btTureData = TripleDES.decode(evt.data)
          // console.log('接收消息：', btTureData)
          if (callObject != null) {
            messageCB(callObject, btTureData)
          } else {
            messageCB(btTureData)
          }
        }
      }
      // 连接关闭
      this._ws.onclose = function (evt) {

        if (closeCB != null) {
          if (callObject != null) {
            closeCB(callObject, evt)
          } else {
            closeCB(evt)
          }
        }
      }
      // 通信错误
      this._ws.onerror = function (evt) {

        if (errorCB != null) {
          if (callObject != null) {
            errorCB(callObject, evt)
          } else {
            errorCB(evt)
          }
        }
      }
    } else {
      // 弹出信息：不支持网络
      // console.error("不支持网络")
    }
  }
}
