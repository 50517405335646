import "./public-path";
import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App'
import ElementUI from 'element-ui'
import echarts from 'echarts'
import BUS from './common/service/bus'
import VueDND from 'awe-dnd'
// const hls = require('videojs-contrib-hls')//"videojs-contrib-hls": "^5.15.0",
import 'element-ui/lib/theme-chalk/index.css'
import 'nprogress/nprogress.css'
import 'normalize.css/normalize.css'
import './assets/style/layout.scss'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(BUS)
Vue.use(VueDND)
Vue.prototype.$echarts = echarts
// Vue.use(hls)//兼容m3u8格式播放器
// 监听localstorage的方法
Vue.prototype.$addStorageEvent = function (type, key, data) {
  if (type === 1) {
    // 创建一个StorageEvent事件
    const newStorageEvent = document.createEvent('StorageEvent')
    const storage = {
      setItem: function (k, val) {
        localStorage.setItem(k, val)
        // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          'setItem',
          false,
          false,
          k,
          null,
          val,
          null,
          null
        )
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, data)
  } else {
    // 创建一个StorageEvent事件
    const newStorageEvent = document.createEvent('StorageEvent')
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val)
        // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          'setItem',
          false,
          false,
          k,
          null,
          val,
          null,
          null
        )
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, data)
  }
}

let instance = null;
function render(props = {}) {
  const { container } = props;
  instance = new Vue({
    router,
    store,
    render: h => h(App),
    components: { App },
    data() {
      return {
        THEME_NAME: 'dark'
      }
    },
    mounted() {
      if (window.localStorage.getItem('YH_BF_THEME')) {
        this.THEME_NAME = window.localStorage
          .getItem('YH_BF_THEME')
          .replace('theme-', '')
      }
      window.addEventListener('setItem', e => {
        this.THEME_NAME = e.newValue.replace('theme-', '')
      })
    }
  }).$mount(container ? container.querySelector('#app') : '#app')
}

export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}
export async function mount(props) {
  console.log('[vue] props from main framework', props);
  render(props);
}
export async function unmount() {
  console.log('unmounted');
  instance.$el.innerHTML = '';
  instance.$destroy();
  instance = null;
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}